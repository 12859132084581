import { useEffect, ReactNode } from "react";

import { useHistory } from "react-router-dom";

import { refresh, silentRefresh } from "@libs/api/auth";
import { getAuthActions, useAuthStatus } from "@stores/auth";

function LoginChecker({ children }: { children: ReactNode }) {
  const history = useHistory();
  const authStatus = useAuthStatus();
  const { setAuthStatus } = getAuthActions();

  useEffect(
    function checkSignInStatusByRefresh() {
      (async () => {
        try {
          await refresh();
          silentRefresh();
        } catch (e) {
          setAuthStatus("unauthorized");
        }
      })();
    },
    [history]
  );

  if (authStatus === "pending") return null;

  return <>{children}</>;
}

export default LoginChecker;
