import { ReactComponent as EmailLogo } from "@assets/images/email-logo.svg";
import googleLogo from "@assets/images/google-logo.png";

type Props = {
  onClick: () => void;
  method: "google" | "email";
  type: "signUp" | "signIn";
};

const methodTypeComponentMap = {
  google: {
    signUp: {
      text: "Sign up with google",
      icon: <img src={googleLogo} />,
    },
    signIn: {
      text: "Continue with google",
      icon: <img src={googleLogo} />,
    },
  },
  email: {
    signUp: {
      text: "Sign up with email",
      icon: <EmailLogo />,
    },
    signIn: {
      text: "Continue with email",
      icon: <EmailLogo />,
    },
  },
};

function SignButton({ onClick, method, type }: Props) {
  return (
    <button
      className="interaction-bg w-75 h-15 shadow-2 dark:bg-dark-theme-200 flex items-center justify-center gap-3 rounded-lg"
      onClick={onClick}
    >
      {methodTypeComponentMap[method][type].icon}
      <p className="text-body-md font-medium">{methodTypeComponentMap[method][type].text}</p>
    </button>
  );
}

export default SignButton;
