import { OAuthProvider } from "./OAuthClient";

type OAuthInfo = {
  clientId: string;
  clientSecret?: string;
  callbackUrl: string;
  scope?: string;
  authorizationUrl: string;
  tokenUrl?: string;
};

export const OAuthConfig: Record<OAuthProvider, OAuthInfo> = {
  google: {
    clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    scope: "https://www.googleapis.com/auth/userinfo.email",
    callbackUrl: CONFIG.OAUTH.GOOGLE.CALLBACK_URI,
    authorizationUrl: "https://accounts.google.com/o/oauth2/v2/auth",
  },
};
