import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"]
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type BaseError = IBaseError & {
  __typename?: "BaseError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type CharacterDescription = ICharacterDescription & {
  __typename?: "CharacterDescription";
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CharacterDescriptionInput = {
  content: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type CharacterQueryRes = BaseError | CharacterResponse;

export type CharacterResponse = {
  __typename?: "CharacterResponse";
  _id: Scalars["String"]["output"];
  descriptions: Array<CharacterDescription>;
  name: Scalars["String"]["output"];
  nodeId: Scalars["String"]["output"];
  profileImageUrl?: Maybe<Scalars["String"]["output"]>;
  storyId: Scalars["String"]["output"];
};

export type CharacterTreeNodeResponse = {
  __typename?: "CharacterTreeNodeResponse";
  _id: Scalars["String"]["output"];
  characterId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nextNodeId?: Maybe<Scalars["String"]["output"]>;
  parentId?: Maybe<Scalars["String"]["output"]>;
  prevNodeId?: Maybe<Scalars["String"]["output"]>;
  profileImageUrl?: Maybe<Scalars["String"]["output"]>;
  storyId: Scalars["String"]["output"];
  type: CharacterTreeNodeType;
};

export enum CharacterTreeNodeType {
  Character = "CHARACTER",
  Directory = "DIRECTORY",
}

export type CharacterTreeNodeWithChildren = {
  __typename?: "CharacterTreeNodeWithChildren";
  _id: Scalars["String"]["output"];
  characterId?: Maybe<Scalars["String"]["output"]>;
  children: Array<CharacterTreeNodeWithChildren>;
  name: Scalars["String"]["output"];
  nextNodeId?: Maybe<Scalars["String"]["output"]>;
  parentId?: Maybe<Scalars["String"]["output"]>;
  prevNodeId?: Maybe<Scalars["String"]["output"]>;
  profileImageUrl?: Maybe<Scalars["String"]["output"]>;
  storyId: Scalars["String"]["output"];
  type: CharacterTreeNodeType;
};

export type CharacterTreeQueryRes = BaseError | CharacterTreeResponse | InvalidTreeNodeCreateInputError;

export type CharacterTreeResponse = {
  __typename?: "CharacterTreeResponse";
  characterTree: Array<CharacterTreeNodeWithChildren>;
};

export type CompleteOnboardingTourMutationResponse = BaseError | SuccessFailureResponse;

export type CreateCharacterTreeNodeInput = {
  name: Scalars["String"]["input"];
  parentId?: InputMaybe<Scalars["String"]["input"]>;
  storyId: Scalars["String"]["input"];
  type: CharacterTreeNodeType;
};

export type CreateCharacterTreeNodeMutationRes =
  | BaseError
  | CharacterTreeNodeResponse
  | InvalidTreeNodeCreateInputError;

export type CreateCharacterTreeNodeWithTemplateInput = {
  id: Scalars["String"]["input"];
  parentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateCharacterTreeNodeWithTemplateMutationRes = BaseError | CharacterTreeNodeResponse;

export type CreateFileTreeNodeInput = {
  name: Scalars["String"]["input"];
  parentId?: InputMaybe<Scalars["String"]["input"]>;
  storyId: Scalars["String"]["input"];
  type: FileTreeNodeType;
};

export type CreateFileTreeNodeMutationResponse = BaseError | FileTreeNodeResponse | InvalidTreeNodeCreateInputError;

export type CreateHistoryInput = {
  content: Scalars["String"]["input"];
  fileId: Scalars["String"]["input"];
  storyId: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  wordCount: Scalars["Float"]["input"];
};

export type DeleteFileTreeNodeMutationRes = BaseError | SuccessFailureResponse;

export type DuplicateCharacterTreeNodeMutationRes = BaseError | CharacterTreeNodeResponse | FailToDuplicateTreeError;

export type DuplicateFileTreeNodeMutationRes = BaseError | FailToDuplicateTreeError | FileTreeNodeResponse;

export type DuplicatedTitleWithinAuthorAccountError = IBaseError & {
  __typename?: "DuplicatedTitleWithinAuthorAccountError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type EmailSignInInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type EmailSignInMutationResponse =
  | BaseError
  | NeedSignUpError
  | RefreshResponse
  | WrongPasswordError
  | WrongSignUpProviderError;

export type EmailSignUpInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type EmailSignUpMutationResponse = BaseError | HasSignedUpAccountError | RefreshResponse;

export type EmailVerificationTokenExpiredError = IBaseError & {
  __typename?: "EmailVerificationTokenExpiredError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type ExportFilesInput = {
  fileIds: Array<Scalars["String"]["input"]>;
  storyId: Scalars["String"]["input"];
  type: FileExportType;
  withMerge: Scalars["Boolean"]["input"];
};

export type ExportFilesMutationRes = BaseError | ExportFilesResponse;

export type ExportFilesResponse = {
  __typename?: "ExportFilesResponse";
  fileName: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type FailToDuplicateTreeError = IBaseError & {
  __typename?: "FailToDuplicateTreeError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type FailToRefreshError = IBaseError & {
  __typename?: "FailToRefreshError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type FailToUploadError = IBaseError & {
  __typename?: "FailToUploadError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export enum FileExportType {
  Docx = "DOCX",
  Txt = "TXT",
}

export type FileIsNotPublicError = IBaseError & {
  __typename?: "FileIsNotPublicError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type FileQueryResponse = BaseError | FileResponse;

export type FileResponse = {
  __typename?: "FileResponse";
  _id: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  fileTreeNodeId: Scalars["String"]["output"];
  isPublic: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  wordCount: Scalars["Float"]["output"];
};

export type FileSearchInput = {
  storyId: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
};

export type FileSearchQueryResponse = BaseError | FileSearchResultsResponse;

export type FileSearchResult = {
  __typename?: "FileSearchResult";
  _id: Scalars["String"]["output"];
  highlights: Array<SearchHighlightResponse>;
  name: Scalars["String"]["output"];
};

export type FileSearchResultsResponse = {
  __typename?: "FileSearchResultsResponse";
  hitCount: Scalars["Float"]["output"];
  searchResults: Array<FileSearchResult>;
};

export type FileTreeNodeResponse = {
  __typename?: "FileTreeNodeResponse";
  _id: Scalars["String"]["output"];
  fileId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nextNodeId: Scalars["String"]["output"];
  parentId: Scalars["String"]["output"];
  prevNodeId: Scalars["String"]["output"];
  storyId: Scalars["String"]["output"];
  type: FileTreeNodeType;
};

export enum FileTreeNodeType {
  Directory = "DIRECTORY",
  File = "FILE",
}

export type FileTreeNodeWithChildren = {
  __typename?: "FileTreeNodeWithChildren";
  _id: Scalars["String"]["output"];
  children: Array<FileTreeNodeWithChildren>;
  fileId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nextNodeId?: Maybe<Scalars["String"]["output"]>;
  parentId?: Maybe<Scalars["String"]["output"]>;
  prevNodeId?: Maybe<Scalars["String"]["output"]>;
  storyId: Scalars["String"]["output"];
  type: FileTreeNodeType;
};

export type FileTreeNodeWithWordCountRes = {
  __typename?: "FileTreeNodeWithWordCountRes";
  _id: Scalars["String"]["output"];
  children: Array<FileTreeNodeWithWordCountRes>;
  fileId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nextNodeId?: Maybe<Scalars["String"]["output"]>;
  parentId?: Maybe<Scalars["String"]["output"]>;
  prevNodeId?: Maybe<Scalars["String"]["output"]>;
  storyId: Scalars["String"]["output"];
  type: FileTreeNodeType;
  wordCount: Scalars["Float"]["output"];
};

export type FileTreeQueryResponse = BaseError | FileTreeResponse | InternalTreeError;

export type FileTreeResponse = {
  __typename?: "FileTreeResponse";
  fileTree: Array<FileTreeNodeWithChildren>;
};

export type FileTreeWithWordCountQueryRes = BaseError | FileTreeWithWordCountResponse | InternalTreeError;

export type FileTreeWithWordCountResponse = {
  __typename?: "FileTreeWithWordCountResponse";
  fileTree: Array<FileTreeNodeWithWordCountRes>;
};

export type GetHistoriesInput = {
  fileId: Scalars["String"]["input"];
  storyId: Scalars["String"]["input"];
};

export type GetPublishedFileQueryResponse = BaseError | FileIsNotPublicError | PublishedFileResponse;

export type GetStoryInput = {
  storyId: Scalars["String"]["input"];
};

export type HasSignedUpAccountError = IBaseError & {
  __typename?: "HasSignedUpAccountError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type HistoriesQueryRes = BaseError | HistoriesResponse;

export type HistoriesResponse = {
  __typename?: "HistoriesResponse";
  histories: Array<HistoryWithoutContentResponse>;
};

export type HistoryQueryRes = BaseError | HistoryResponse;

export type HistoryResponse = {
  __typename?: "HistoryResponse";
  _id: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  wordCount: Scalars["Float"]["output"];
};

export type HistoryWithoutContentResponse = {
  __typename?: "HistoryWithoutContentResponse";
  _id: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
  wordCount: Scalars["Float"]["output"];
};

export type IBaseError = {
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type ICharacterDescription = {
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type InternalTreeError = IBaseError & {
  __typename?: "InternalTreeError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type InvalidEmailVerificationTokenError = IBaseError & {
  __typename?: "InvalidEmailVerificationTokenError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type InvalidTreeNodeCreateInputError = IBaseError & {
  __typename?: "InvalidTreeNodeCreateInputError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type MemoQueryResponse = BaseError | MemoResponse;

export type MemoResponse = {
  __typename?: "MemoResponse";
  content: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  storyId: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  completeOnboardingTour: CompleteOnboardingTourMutationResponse;
  createCharacterTreeNode: CreateCharacterTreeNodeMutationRes;
  createCharacterTreeNodeWithTemplate: CreateCharacterTreeNodeWithTemplateMutationRes;
  createFileTreeNode: CreateFileTreeNodeMutationResponse;
  createHistory: SuccessFailureResponse;
  deleteCharacterTreeNode: MutationSuccessFailureRes;
  deleteFileTreeNode: DeleteFileTreeNodeMutationRes;
  deleteFileTreeNodePermanently: DeleteFileTreeNodeMutationRes;
  deleteHistory: SuccessFailureResponse;
  deleteStory: SuccessFailureResponse;
  duplicateCharacterTreeNode: DuplicateCharacterTreeNodeMutationRes;
  duplicateFileTreeNode: DuplicateFileTreeNodeMutationRes;
  emailSignIn: EmailSignInMutationResponse;
  emailSignUp: EmailSignUpMutationResponse;
  exportFiles: ExportFilesMutationRes;
  refresh: RefreshMutationResponse;
  resendEmailVerificationMail: ResendEmailVerificationMailMutationResponse;
  resetPassword: ResetPasswordMutationResponse;
  restoreFileTreeNode: RestoreFileTreeNodeMutationRes;
  restoreHistory: FileQueryResponse;
  sendResetPasswordMail: SendResetPasswordMailMutationResponse;
  signInOnlyDevelopment: RefreshResponse;
  signInOrSignUpByCode: SignInOrSignUpByCodeMutationResponse;
  signOut: Scalars["Boolean"]["output"];
  startNewStory: StartNewStoryMutationResponse;
  submitSurvey: SubmitSurveyMutationResponse;
  testUpload: Scalars["Boolean"]["output"];
  toggleStoryPin: StoryQueryResponse;
  updateAccountSettings: UserQueryResponse;
  updateCharacter: UpdateCharacterMutationRes;
  updateCharacterProfileUrl: UpdateCharacterProfileMutationRes;
  updateCharacterTree: MutationSuccessFailureRes;
  updateCharacterTreeNodeAttribute: UpdateCharacterTreeNodeAttributeRes;
  updateFileContent: UpdateFileContentMutationResponse;
  updateFilePublicStatus: FileQueryResponse;
  updateFileTree: UpdateFileTreeMutationRes;
  updateFileTreeNodeAttribute: UpdateFileTreeNodeAttributeMutationResponse;
  updateHistoryTitle: SuccessFailureResponse;
  updateMemo: MemoQueryResponse;
  updatePreferences: UpdatePreferencesMutationResponse;
  updateStory: StoryQueryResponse;
  uploadEditorImage: UploadEditorImageMutationRes;
  verifyEmail: VerifyEmailMutationResponse;
};

export type MutationCreateCharacterTreeNodeArgs = {
  input: CreateCharacterTreeNodeInput;
};

export type MutationCreateCharacterTreeNodeWithTemplateArgs = {
  input: CreateCharacterTreeNodeWithTemplateInput;
};

export type MutationCreateFileTreeNodeArgs = {
  input: CreateFileTreeNodeInput;
};

export type MutationCreateHistoryArgs = {
  input: CreateHistoryInput;
};

export type MutationDeleteCharacterTreeNodeArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteFileTreeNodeArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteFileTreeNodePermanentlyArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteHistoryArgs = {
  historyId: Scalars["String"]["input"];
};

export type MutationDeleteStoryArgs = {
  storyId: Scalars["String"]["input"];
};

export type MutationDuplicateCharacterTreeNodeArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDuplicateFileTreeNodeArgs = {
  id: Scalars["String"]["input"];
};

export type MutationEmailSignInArgs = {
  input: EmailSignInInput;
};

export type MutationEmailSignUpArgs = {
  input: EmailSignUpInput;
};

export type MutationExportFilesArgs = {
  input: ExportFilesInput;
};

export type MutationResendEmailVerificationMailArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationRestoreFileTreeNodeArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRestoreHistoryArgs = {
  input: RestoreHistoryInput;
};

export type MutationSendResetPasswordMailArgs = {
  email: Scalars["String"]["input"];
};

export type MutationSignInOnlyDevelopmentArgs = {
  input: SignInOnlyDevelopmentInput;
};

export type MutationSignInOrSignUpByCodeArgs = {
  input: SignInOrSignUpInput;
};

export type MutationStartNewStoryArgs = {
  input: StartNewStoryInput;
};

export type MutationSubmitSurveyArgs = {
  input: SubmitSurveyInput;
};

export type MutationTestUploadArgs = {
  input: TestUploadDto;
};

export type MutationToggleStoryPinArgs = {
  storyId: Scalars["String"]["input"];
};

export type MutationUpdateAccountSettingsArgs = {
  input: UpdateAccountSettingsInput;
};

export type MutationUpdateCharacterArgs = {
  input: UpdateCharacterInput;
};

export type MutationUpdateCharacterProfileUrlArgs = {
  input: UpdateCharacterProfileUrlInput;
};

export type MutationUpdateCharacterTreeArgs = {
  input: UpdateTreeInput;
};

export type MutationUpdateCharacterTreeNodeAttributeArgs = {
  input: UpdateTreeNodeAttributeInput;
};

export type MutationUpdateFileContentArgs = {
  input: UpdateFileContentInput;
};

export type MutationUpdateFilePublicStatusArgs = {
  input: UpdateFilePublicStatusInput;
};

export type MutationUpdateFileTreeArgs = {
  input: UpdateTreeInput;
};

export type MutationUpdateFileTreeNodeAttributeArgs = {
  input: UpdateTreeNodeAttributeInput;
};

export type MutationUpdateHistoryTitleArgs = {
  input: UpdateHistoryTitleInput;
};

export type MutationUpdateMemoArgs = {
  input: UpdateMemoInput;
};

export type MutationUpdatePreferencesArgs = {
  input: Array<UpdatePreferencesInput>;
};

export type MutationUpdateStoryArgs = {
  input: UpdateStoryInput;
};

export type MutationUploadEditorImageArgs = {
  input: UploadEditorImageInput;
};

export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type MutationSuccessFailureRes = BaseError | SuccessFailureResponse;

export type NeedSignInData = {
  __typename?: "NeedSignInData";
  email: Scalars["String"]["output"];
};

export type NeedSignInError = IBaseError & {
  __typename?: "NeedSignInError";
  data: NeedSignInData;
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type NeedSignUpError = IBaseError & {
  __typename?: "NeedSignUpError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type NewStoryResponse = {
  __typename?: "NewStoryResponse";
  fileTreeNode: FileTreeNodeResponse;
  story: StoryResponse;
};

export type NotRegisteredEmailError = IBaseError & {
  __typename?: "NotRegisteredEmailError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type OnboardingResponse = {
  __typename?: "OnboardingResponse";
  isOnboardingCompleted: Scalars["Boolean"]["output"];
  isSurveyCompleted: Scalars["Boolean"]["output"];
};

export type PasswordResetTokenExpiredError = IBaseError & {
  __typename?: "PasswordResetTokenExpiredError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export enum PreferenceCategory {
  Display = "DISPLAY",
  Editor = "EDITOR",
}

export enum PreferenceName {
  EditorMargin = "EDITOR_MARGIN",
  Font = "FONT",
  FontSize = "FONT_SIZE",
  LineSpacing = "LINE_SPACING",
  SmartQuote = "SMART_QUOTE",
  TextIndent = "TEXT_INDENT",
  TextReplaceMap = "TEXT_REPLACE_MAP",
  TextReplaceMode = "TEXT_REPLACE_MODE",
  Theme = "THEME",
  TypewriterMode = "TYPEWRITER_MODE",
  WordCountType = "WORD_COUNT_TYPE",
}

export type PreferenceResponse = {
  __typename?: "PreferenceResponse";
  category: PreferenceCategory;
  name: PreferenceName;
  value: Scalars["String"]["output"];
};

export type PublishedFileResponse = {
  __typename?: "PublishedFileResponse";
  _id: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  fileTreeNodeId: Scalars["String"]["output"];
  isPublic: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  wordCount: Scalars["Float"]["output"];
};

export type Query = {
  __typename?: "Query";
  character: CharacterQueryRes;
  characterTreeOfStory: CharacterTreeQueryRes;
  file: FileQueryResponse;
  fileSearch: FileSearchQueryResponse;
  fileTreeOfStory: FileTreeQueryResponse;
  fileTreeWithWordCountOfStory: FileTreeWithWordCountQueryRes;
  getMemoByStoryId: MemoQueryResponse;
  getPublishedFile: GetPublishedFileQueryResponse;
  histories: HistoriesQueryRes;
  history: HistoryQueryRes;
  stories: StoriesQueryResponse;
  story: StoryQueryResponse;
  trashCanFileTreeOfStory: TrashCanFileTreeQueryRes;
  user: UserQueryResponse;
};

export type QueryCharacterArgs = {
  id: Scalars["String"]["input"];
};

export type QueryCharacterTreeOfStoryArgs = {
  storyId: Scalars["String"]["input"];
};

export type QueryFileArgs = {
  id: Scalars["String"]["input"];
};

export type QueryFileSearchArgs = {
  input: FileSearchInput;
};

export type QueryFileTreeOfStoryArgs = {
  storyId: Scalars["String"]["input"];
};

export type QueryFileTreeWithWordCountOfStoryArgs = {
  storyId: Scalars["String"]["input"];
};

export type QueryGetMemoByStoryIdArgs = {
  storyId: Scalars["String"]["input"];
};

export type QueryGetPublishedFileArgs = {
  id: Scalars["String"]["input"];
};

export type QueryHistoriesArgs = {
  input: GetHistoriesInput;
};

export type QueryHistoryArgs = {
  historyId: Scalars["String"]["input"];
};

export type QueryStoryArgs = {
  input: GetStoryInput;
};

export type QueryTrashCanFileTreeOfStoryArgs = {
  storyId: Scalars["String"]["input"];
};

export type RefreshMutationResponse = BaseError | FailToRefreshError | RefreshResponse;

export type RefreshResponse = {
  __typename?: "RefreshResponse";
  accessToken: Scalars["String"]["output"];
  isNewUser: Scalars["Boolean"]["output"];
  user?: Maybe<UserResponse>;
};

export type ResendEmailVerificationMailMutationResponse = BaseError | SuccessFailureResponse;

export type ResetPasswordInput = {
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type ResetPasswordMutationResponse =
  | BaseError
  | InvalidEmailVerificationTokenError
  | PasswordResetTokenExpiredError
  | SuccessFailureResponse;

export type RestoreFileTreeNodeMutationRes = BaseError | SuccessFailureResponse;

export type RestoreHistoryInput = {
  currentContent: Scalars["String"]["input"];
  currentWordCount: Scalars["Float"]["input"];
  historyId: Scalars["String"]["input"];
};

export type SearchHighlightResponse = {
  __typename?: "SearchHighlightResponse";
  texts: Array<SearchHighlightTextResponse>;
};

export type SearchHighlightTextResponse = {
  __typename?: "SearchHighlightTextResponse";
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SendResetPasswordMailMutationResponse = BaseError | NotRegisteredEmailError | SuccessFailureResponse;

export type SignInOnlyDevelopmentInput = {
  id: Scalars["String"]["input"];
};

export type SignInOrSignUpByCodeMutationResponse = BaseError | NeedSignUpError | RefreshResponse;

export type SignInOrSignUpInput = {
  code: Scalars["String"]["input"];
  provider: Scalars["String"]["input"];
};

export type StartNewStoryInput = {
  category: StoryCategory;
  coverImage?: InputMaybe<Scalars["Upload"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title: Scalars["String"]["input"];
};

export type StartNewStoryMutationResponse = BaseError | DuplicatedTitleWithinAuthorAccountError | NewStoryResponse;

export type StoriesQueryResponse = BaseError | StoriesResponse;

export type StoriesResponse = {
  __typename?: "StoriesResponse";
  stories: Array<StoryResponse>;
};

export enum StoryCategory {
  Action = "ACTION",
  Adventure = "ADVENTURE",
  Erotica = "EROTICA",
  Fanfiction = "FANFICTION",
  Fantasy = "FANTASY",
  HistoricalFiction = "HISTORICAL_FICTION",
  Mystery = "MYSTERY",
  Other = "OTHER",
  Paranormal = "PARANORMAL",
  Romance = "ROMANCE",
  SciFi = "SCI_FI",
  Thriller = "THRILLER",
}

export type StoryQueryResponse = BaseError | StoryResponse;

export type StoryResponse = {
  __typename?: "StoryResponse";
  _id: Scalars["String"]["output"];
  authorId: Scalars["String"]["output"];
  category: StoryCategory;
  coverImageUrl?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  pinnedAt?: Maybe<Scalars["DateTime"]["output"]>;
  tags: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type SubmitSurveyInput = {
  surveyJson: Scalars["String"]["input"];
};

export type SubmitSurveyMutationResponse = BaseError | SuccessFailureResponse;

export type SuccessFailureResponse = {
  __typename?: "SuccessFailureResponse";
  success: Scalars["Boolean"]["output"];
};

export type TestUploadDto = {
  file: Scalars["Upload"]["input"];
};

export type TrashCanFileTreeNode = {
  __typename?: "TrashCanFileTreeNode";
  _id: Scalars["String"]["output"];
  children: Array<TrashCanFileTreeNode>;
  deletedAt: Scalars["DateTime"]["output"];
  fileId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  nextNodeId?: Maybe<Scalars["String"]["output"]>;
  parentId?: Maybe<Scalars["String"]["output"]>;
  prevNodeId?: Maybe<Scalars["String"]["output"]>;
  storyId: Scalars["String"]["output"];
  type: FileTreeNodeType;
};

export type TrashCanFileTreeQueryRes = BaseError | InternalTreeError | TrashCanFileTreeResponse;

export type TrashCanFileTreeResponse = {
  __typename?: "TrashCanFileTreeResponse";
  trashCanFileTree: Array<TrashCanFileTreeNode>;
};

export type UpdateAccountSettingsInput = {
  marketingAgreement: Scalars["Boolean"]["input"];
};

export type UpdateCharacterInput = {
  descriptions?: InputMaybe<Array<CharacterDescriptionInput>>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCharacterMutationRes = BaseError | CharacterResponse;

export type UpdateCharacterProfileMutationRes = BaseError | CharacterResponse | FailToUploadError;

export type UpdateCharacterProfileUrlInput = {
  file: Scalars["Upload"]["input"];
  id: Scalars["String"]["input"];
};

export type UpdateCharacterTreeNodeAttributeRes =
  | BaseError
  | CharacterTreeNodeResponse
  | InvalidTreeNodeCreateInputError;

export type UpdateFileContentInput = {
  _id: Scalars["String"]["input"];
  content: Scalars["String"]["input"];
  wordCount?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UpdateFileContentMutationResponse = BaseError | SuccessFailureResponse;

export type UpdateFilePublicStatusInput = {
  fileId: Scalars["String"]["input"];
  isPublic: Scalars["Boolean"]["input"];
};

export type UpdateFileTreeMutationRes = BaseError | SuccessFailureResponse;

export type UpdateFileTreeNodeAttributeMutationResponse = BaseError | FileTreeNodeResponse;

export type UpdateHistoryTitleInput = {
  id: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type UpdateMemoInput = {
  content: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
};

export type UpdatePreferencesInput = {
  category: PreferenceCategory;
  name: PreferenceName;
  value: Scalars["String"]["input"];
};

export type UpdatePreferencesMutationResponse = BaseError | UserResponse;

export type UpdateStoryInput = {
  category?: InputMaybe<StoryCategory>;
  coverImage?: InputMaybe<Scalars["Upload"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  storyId: Scalars["String"]["input"];
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateTreeInput = {
  id: Scalars["String"]["input"];
  nextNodeId?: InputMaybe<Scalars["String"]["input"]>;
  parentId?: InputMaybe<Scalars["String"]["input"]>;
  prevNodeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateTreeNodeAttributeInput = {
  _id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type UploadEditorImageInput = {
  file: Scalars["Upload"]["input"];
};

export type UploadEditorImageMutationRes = BaseError | UploadEditorImageResponse;

export type UploadEditorImageResponse = {
  __typename?: "UploadEditorImageResponse";
  url: Scalars["String"]["output"];
};

export type UserQueryResponse = BaseError | UserResponse;

export type UserResponse = {
  __typename?: "UserResponse";
  _id: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  firstStoryCreated: Scalars["Boolean"]["output"];
  isEmailVerified: Scalars["Boolean"]["output"];
  marketingAgreement?: Maybe<Scalars["Boolean"]["output"]>;
  marketingAgreementUpdatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  onboarding: OnboardingResponse;
  preferences: Array<PreferenceResponse>;
  signUpMethod: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type VerifyEmailInput = {
  token: Scalars["String"]["input"];
};

export type VerifyEmailMutationResponse =
  | BaseError
  | EmailVerificationTokenExpiredError
  | InvalidEmailVerificationTokenError
  | NeedSignInError
  | RefreshResponse;

export type WrongPasswordError = IBaseError & {
  __typename?: "WrongPasswordError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type WrongSignUpProviderError = IBaseError & {
  __typename?: "WrongSignUpProviderError";
  errorCode: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type FileTreeNodeWithChildren_FragmentFragment = {
  __typename?: "FileTreeNodeWithChildren";
  _id: string;
  fileId?: string | null;
  name: string;
  type: FileTreeNodeType;
  parentId?: string | null;
  nextNodeId?: string | null;
  prevNodeId?: string | null;
  storyId: string;
};

export type FileTreeOfStoryQueryVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type FileTreeOfStoryQuery = {
  __typename?: "Query";
  fileTreeOfStory:
    | { __typename: "BaseError" }
    | {
        __typename: "FileTreeResponse";
        fileTree: Array<{
          __typename?: "FileTreeNodeWithChildren";
          _id: string;
          fileId?: string | null;
          name: string;
          type: FileTreeNodeType;
          parentId?: string | null;
          nextNodeId?: string | null;
          prevNodeId?: string | null;
          storyId: string;
          children: Array<{
            __typename?: "FileTreeNodeWithChildren";
            _id: string;
            fileId?: string | null;
            name: string;
            type: FileTreeNodeType;
            parentId?: string | null;
            nextNodeId?: string | null;
            prevNodeId?: string | null;
            storyId: string;
          }>;
        }>;
      }
    | { __typename: "InternalTreeError" };
};

export type InitPreferenceQueryVariables = Exact<{ [key: string]: never }>;

export type InitPreferenceQuery = {
  __typename?: "Query";
  user:
    | { __typename: "BaseError" }
    | {
        __typename: "UserResponse";
        preferences: Array<{
          __typename?: "PreferenceResponse";
          category: PreferenceCategory;
          name: PreferenceName;
          value: string;
        }>;
      };
};

export type RefreshReqMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshReqMutation = {
  __typename?: "Mutation";
  refresh:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename?: "FailToRefreshError" }
    | { __typename: "RefreshResponse"; accessToken: string };
};

export type SignInReqMutationVariables = Exact<{
  code: Scalars["String"]["input"];
  provider: Scalars["String"]["input"];
}>;

export type SignInReqMutation = {
  __typename?: "Mutation";
  signInOrSignUpByCode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "NeedSignUpError" }
    | {
        __typename: "RefreshResponse";
        accessToken: string;
        isNewUser: boolean;
        user?: { __typename?: "UserResponse"; email: string } | null;
      };
};

export type SignOutReqMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutReqMutation = { __typename?: "Mutation"; signOut: boolean };

export type EmailSignUpReqMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type EmailSignUpReqMutation = {
  __typename?: "Mutation";
  emailSignUp:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "HasSignedUpAccountError" }
    | {
        __typename: "RefreshResponse";
        accessToken: string;
        user?: { __typename?: "UserResponse"; email: string } | null;
      };
};

export type EmailSignInReqMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type EmailSignInReqMutation = {
  __typename?: "Mutation";
  emailSignIn:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "NeedSignUpError" }
    | {
        __typename: "RefreshResponse";
        accessToken: string;
        user?: { __typename?: "UserResponse"; email: string } | null;
      }
    | { __typename: "WrongPasswordError" }
    | { __typename: "WrongSignUpProviderError" };
};

export type VerifyEmailReqMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type VerifyEmailReqMutation = {
  __typename?: "Mutation";
  verifyEmail:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "EmailVerificationTokenExpiredError" }
    | { __typename: "InvalidEmailVerificationTokenError" }
    | {
        __typename: "NeedSignInError";
        message: string;
        errorCode: string;
        data: { __typename?: "NeedSignInData"; email: string };
      }
    | {
        __typename: "RefreshResponse";
        accessToken: string;
        user?: { __typename?: "UserResponse"; email: string } | null;
      };
};

export type ResendEmailVerificationMailReqMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ResendEmailVerificationMailReqMutation = {
  __typename?: "Mutation";
  resendEmailVerificationMail:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type SendResetPasswordMailReqMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type SendResetPasswordMailReqMutation = {
  __typename?: "Mutation";
  sendResetPasswordMail:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "NotRegisteredEmailError" }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type ResetPasswordReqMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type ResetPasswordReqMutation = {
  __typename?: "Mutation";
  resetPassword:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "InvalidEmailVerificationTokenError" }
    | { __typename: "PasswordResetTokenExpiredError" }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type UpdateCharacterProfileUrlMutationVariables = Exact<{
  input: UpdateCharacterProfileUrlInput;
}>;

export type UpdateCharacterProfileUrlMutation = {
  __typename?: "Mutation";
  updateCharacterProfileUrl:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "CharacterResponse";
        _id: string;
        name: string;
        profileImageUrl?: string | null;
        nodeId: string;
        storyId: string;
        descriptions: Array<{ __typename?: "CharacterDescription"; title: string; content: string }>;
      }
    | { __typename: "FailToUploadError" };
};

export type UpdateCharacterMutationVariables = Exact<{
  input: UpdateCharacterInput;
}>;

export type UpdateCharacterMutation = {
  __typename?: "Mutation";
  updateCharacter:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "CharacterResponse";
        _id: string;
        name: string;
        profileImageUrl?: string | null;
        nodeId: string;
        storyId: string;
        descriptions: Array<{ __typename?: "CharacterDescription"; title: string; content: string }>;
      };
};

export type CreateCharacterTreeNodeMutationVariables = Exact<{
  input: CreateCharacterTreeNodeInput;
}>;

export type CreateCharacterTreeNodeMutation = {
  __typename?: "Mutation";
  createCharacterTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "CharacterTreeNodeResponse";
        _id: string;
        name: string;
        characterId?: string | null;
        type: CharacterTreeNodeType;
        prevNodeId?: string | null;
        nextNodeId?: string | null;
        parentId?: string | null;
      }
    | { __typename: "InvalidTreeNodeCreateInputError" };
};

export type CreateCharacterTreeNodeWithTemplateMutationVariables = Exact<{
  input: CreateCharacterTreeNodeWithTemplateInput;
}>;

export type CreateCharacterTreeNodeWithTemplateMutation = {
  __typename?: "Mutation";
  createCharacterTreeNodeWithTemplate:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "CharacterTreeNodeResponse";
        _id: string;
        name: string;
        characterId?: string | null;
        type: CharacterTreeNodeType;
        prevNodeId?: string | null;
        nextNodeId?: string | null;
        parentId?: string | null;
      };
};

export type UpdateCharacterTreeNodeAttributeMutationVariables = Exact<{
  input: UpdateTreeNodeAttributeInput;
}>;

export type UpdateCharacterTreeNodeAttributeMutation = {
  __typename?: "Mutation";
  updateCharacterTreeNodeAttribute:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "CharacterTreeNodeResponse"; _id: string; name: string }
    | { __typename: "InvalidTreeNodeCreateInputError" };
};

export type UpdateCharacterTreeMutationVariables = Exact<{
  input: UpdateTreeInput;
}>;

export type UpdateCharacterTreeMutation = {
  __typename?: "Mutation";
  updateCharacterTree:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type DeleteCharacterTreeNodeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteCharacterTreeNodeMutation = {
  __typename?: "Mutation";
  deleteCharacterTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type DuplicateCharacterTreeNodeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DuplicateCharacterTreeNodeMutation = {
  __typename?: "Mutation";
  duplicateCharacterTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "CharacterTreeNodeResponse";
        _id: string;
        name: string;
        characterId?: string | null;
        type: CharacterTreeNodeType;
        prevNodeId?: string | null;
        nextNodeId?: string | null;
        parentId?: string | null;
      }
    | { __typename: "FailToDuplicateTreeError" };
};

export type BaseError_FragmentFragment = { __typename?: "BaseError"; message: string; errorCode: string };

export type UpdateFileContentMutationVariables = Exact<{
  input: UpdateFileContentInput;
}>;

export type UpdateFileContentMutation = {
  __typename?: "Mutation";
  updateFileContent: { __typename: "BaseError" } | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type UpdateFilePublicStatusMutationVariables = Exact<{
  input: UpdateFilePublicStatusInput;
}>;

export type UpdateFilePublicStatusMutation = {
  __typename?: "Mutation";
  updateFilePublicStatus:
    | { __typename: "BaseError" }
    | { __typename: "FileResponse"; _id: string; name: string; isPublic: boolean; fileTreeNodeId: string };
};

export type ExportFilesMutationVariables = Exact<{
  input: ExportFilesInput;
}>;

export type ExportFilesMutation = {
  __typename?: "Mutation";
  exportFiles: { __typename: "BaseError" } | { __typename: "ExportFilesResponse"; url: string };
};

export type UploadEditorImageMutationVariables = Exact<{
  input: UploadEditorImageInput;
}>;

export type UploadEditorImageMutation = {
  __typename?: "Mutation";
  uploadEditorImage: { __typename: "BaseError" } | { __typename: "UploadEditorImageResponse"; url: string };
};

export type CreateFileTreeNodeReqMutationVariables = Exact<{
  input: CreateFileTreeNodeInput;
}>;

export type CreateFileTreeNodeReqMutation = {
  __typename?: "Mutation";
  createFileTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "FileTreeNodeResponse"; _id: string; fileId?: string | null; name: string; type: FileTreeNodeType }
    | { __typename: "InvalidTreeNodeCreateInputError" };
};

export type UpdateFileTreeNodeAttributeMutationVariables = Exact<{
  input: UpdateTreeNodeAttributeInput;
}>;

export type UpdateFileTreeNodeAttributeMutation = {
  __typename?: "Mutation";
  updateFileTreeNodeAttribute:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "FileTreeNodeResponse"; _id: string; name: string; fileId?: string | null };
};

export type UpdateFileTreeMutationVariables = Exact<{
  input: UpdateTreeInput;
}>;

export type UpdateFileTreeMutation = {
  __typename?: "Mutation";
  updateFileTree:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type DuplicateFileTreeNodeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DuplicateFileTreeNodeMutation = {
  __typename?: "Mutation";
  duplicateFileTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "FailToDuplicateTreeError" }
    | { __typename: "FileTreeNodeResponse"; _id: string; fileId?: string | null; name: string; type: FileTreeNodeType };
};

export type DeleteFileTreeNodeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteFileTreeNodeMutation = {
  __typename?: "Mutation";
  deleteFileTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type CreateHistoryMutationVariables = Exact<{
  input: CreateHistoryInput;
}>;

export type CreateHistoryMutation = {
  __typename?: "Mutation";
  createHistory: { __typename: "SuccessFailureResponse"; success: boolean };
};

export type UpdateHistoryTitleMutationVariables = Exact<{
  input: UpdateHistoryTitleInput;
}>;

export type UpdateHistoryTitleMutation = {
  __typename?: "Mutation";
  updateHistoryTitle: { __typename: "SuccessFailureResponse"; success: boolean };
};

export type DeleteHistoryMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteHistoryMutation = {
  __typename?: "Mutation";
  deleteHistory: { __typename: "SuccessFailureResponse"; success: boolean };
};

export type RestoreHistoryMutationVariables = Exact<{
  input: RestoreHistoryInput;
}>;

export type RestoreHistoryMutation = {
  __typename?: "Mutation";
  restoreHistory: { __typename: "BaseError" } | { __typename: "FileResponse"; _id: string; content: string };
};

export type UpdateMemoMutationVariables = Exact<{
  input: UpdateMemoInput;
}>;

export type UpdateMemoMutation = {
  __typename?: "Mutation";
  updateMemo:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "MemoResponse"; id: string; storyId: string; content: string };
};

export type SearchHighlight_FragmentFragment = {
  __typename?: "SearchHighlightResponse";
  texts: Array<{ __typename?: "SearchHighlightTextResponse"; type: string; value: string }>;
};

export type FileSearchQueryVariables = Exact<{
  input: FileSearchInput;
}>;

export type FileSearchQuery = {
  __typename?: "Query";
  fileSearch:
    | { __typename: "BaseError" }
    | {
        __typename: "FileSearchResultsResponse";
        hitCount: number;
        searchResults: Array<{
          __typename?: "FileSearchResult";
          _id: string;
          name: string;
          highlights: Array<{
            __typename?: "SearchHighlightResponse";
            texts: Array<{ __typename?: "SearchHighlightTextResponse"; type: string; value: string }>;
          }>;
        }>;
      };
};

export type StoryReqQueryVariables = Exact<{
  authorId: Scalars["String"]["input"];
  storyId: Scalars["String"]["input"];
}>;

export type StoryReqQuery = {
  __typename?: "Query";
  story:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "StoryResponse";
        _id: string;
        authorId: string;
        category: StoryCategory;
        coverImageUrl?: string | null;
        description?: string | null;
        tags: Array<string>;
        title: string;
        updatedAt: any;
      };
};

export type StartNewStoryReqMutationVariables = Exact<{
  input: StartNewStoryInput;
}>;

export type StartNewStoryReqMutation = {
  __typename?: "Mutation";
  startNewStory:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "DuplicatedTitleWithinAuthorAccountError" }
    | {
        __typename: "NewStoryResponse";
        story: {
          __typename?: "StoryResponse";
          _id: string;
          authorId: string;
          category: StoryCategory;
          coverImageUrl?: string | null;
          description?: string | null;
          tags: Array<string>;
          title: string;
          updatedAt: any;
        };
        fileTreeNode: { __typename?: "FileTreeNodeResponse"; fileId?: string | null };
      };
};

export type UpdateStoryMutationVariables = Exact<{
  input: UpdateStoryInput;
}>;

export type UpdateStoryMutation = {
  __typename?: "Mutation";
  updateStory:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "StoryResponse";
        _id: string;
        authorId: string;
        category: StoryCategory;
        coverImageUrl?: string | null;
        description?: string | null;
        tags: Array<string>;
        title: string;
        updatedAt: any;
      };
};

export type ToggleStoryPinMutationVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type ToggleStoryPinMutation = {
  __typename?: "Mutation";
  toggleStoryPin:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "StoryResponse";
        _id: string;
        authorId: string;
        category: StoryCategory;
        coverImageUrl?: string | null;
        description?: string | null;
        tags: Array<string>;
        title: string;
        updatedAt: any;
      };
};

export type DeleteStoryMutationVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type DeleteStoryMutation = {
  __typename?: "Mutation";
  deleteStory: { __typename: "SuccessFailureResponse"; success: boolean };
};

export type UserReqQueryVariables = Exact<{ [key: string]: never }>;

export type UserReqQuery = {
  __typename?: "Query";
  user:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "UserResponse"; _id: string; email: string; isEmailVerified: boolean };
};

export type UpdatePreferencesMutationVariables = Exact<{
  input: Array<UpdatePreferencesInput> | UpdatePreferencesInput;
}>;

export type UpdatePreferencesMutation = {
  __typename?: "Mutation";
  updatePreferences:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "UserResponse";
        preferences: Array<{
          __typename?: "PreferenceResponse";
          category: PreferenceCategory;
          name: PreferenceName;
          value: string;
        }>;
      };
};

export type SubmitSurveyMutationVariables = Exact<{
  input: SubmitSurveyInput;
}>;

export type SubmitSurveyMutation = {
  __typename?: "Mutation";
  submitSurvey:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type CompleteOnboardingTourMutationVariables = Exact<{ [key: string]: never }>;

export type CompleteOnboardingTourMutation = {
  __typename?: "Mutation";
  completeOnboardingTour:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type StoriesReqQueryVariables = Exact<{ [key: string]: never }>;

export type StoriesReqQuery = {
  __typename?: "Query";
  stories:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "StoriesResponse";
        stories: Array<{
          __typename?: "StoryResponse";
          _id: string;
          authorId: string;
          category: StoryCategory;
          coverImageUrl?: string | null;
          description?: string | null;
          tags: Array<string>;
          title: string;
          updatedAt: any;
          pinnedAt?: any | null;
        }>;
      };
};

export type CheckUserSurveyReqQueryVariables = Exact<{ [key: string]: never }>;

export type CheckUserSurveyReqQuery = {
  __typename: "Query";
  user:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "UserResponse";
        firstStoryCreated: boolean;
        onboarding: { __typename?: "OnboardingResponse"; isSurveyCompleted: boolean };
      };
};

export type ProfileModalQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileModalQueryQuery = {
  __typename?: "Query";
  user:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "UserResponse";
        _id: string;
        email: string;
        isEmailVerified: boolean;
        signUpMethod: string;
        marketingAgreement?: boolean | null;
      };
};

export type UpdateAccountSettingsMutationVariables = Exact<{
  input: UpdateAccountSettingsInput;
}>;

export type UpdateAccountSettingsMutation = {
  __typename?: "Mutation";
  updateAccountSettings:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "UserResponse";
        _id: string;
        email: string;
        isEmailVerified: boolean;
        signUpMethod: string;
        marketingAgreement?: boolean | null;
      };
};

export type EditorQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type EditorQuery = {
  __typename?: "Query";
  file: { __typename: "BaseError" } | { __typename: "FileResponse"; _id: string; name: string; content: string };
};

export type HistoryListQueryVariables = Exact<{
  input: GetHistoriesInput;
}>;

export type HistoryListQuery = {
  __typename?: "Query";
  histories:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "HistoriesResponse";
        histories: Array<{
          __typename: "HistoryWithoutContentResponse";
          _id: string;
          title: string;
          createdAt: any;
          wordCount: number;
        }>;
      };
};

export type HistoryPreviewQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type HistoryPreviewQuery = {
  __typename?: "Query";
  history:
    | { __typename: "BaseError" }
    | { __typename: "HistoryResponse"; _id: string; content: string; createdAt: any; title: string; wordCount: number };
};

export type EditorHeaderQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type EditorHeaderQuery = {
  __typename?: "Query";
  file:
    | { __typename: "BaseError" }
    | { __typename: "FileResponse"; _id: string; name: string; isPublic: boolean; fileTreeNodeId: string };
};

export type FileTreeNodeWithWordCount_FragmentFragment = {
  __typename?: "FileTreeNodeWithWordCountRes";
  _id: string;
  fileId?: string | null;
  name: string;
  type: FileTreeNodeType;
  parentId?: string | null;
  nextNodeId?: string | null;
  prevNodeId?: string | null;
  storyId: string;
  wordCount: number;
};

export type FileTreeWithWordCountOfStoryQueryVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type FileTreeWithWordCountOfStoryQuery = {
  __typename?: "Query";
  fileTreeWithWordCountOfStory:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "FileTreeWithWordCountResponse";
        fileTree: Array<{
          __typename?: "FileTreeNodeWithWordCountRes";
          _id: string;
          fileId?: string | null;
          name: string;
          type: FileTreeNodeType;
          parentId?: string | null;
          nextNodeId?: string | null;
          prevNodeId?: string | null;
          storyId: string;
          wordCount: number;
          children: Array<{
            __typename?: "FileTreeNodeWithWordCountRes";
            _id: string;
            fileId?: string | null;
            name: string;
            type: FileTreeNodeType;
            parentId?: string | null;
            nextNodeId?: string | null;
            prevNodeId?: string | null;
            storyId: string;
            wordCount: number;
          }>;
        }>;
      }
    | { __typename: "InternalTreeError" };
};

export type CharacterQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CharacterQuery = {
  __typename?: "Query";
  character:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | {
        __typename: "CharacterResponse";
        _id: string;
        name: string;
        profileImageUrl?: string | null;
        nodeId: string;
        storyId: string;
        descriptions: Array<{ __typename?: "CharacterDescription"; title: string; content: string }>;
      };
};

export type CharacterTreeNodeWithChildren_FragmentFragment = {
  __typename?: "CharacterTreeNodeWithChildren";
  _id: string;
  characterId?: string | null;
  name: string;
  type: CharacterTreeNodeType;
  parentId?: string | null;
  nextNodeId?: string | null;
  prevNodeId?: string | null;
  storyId: string;
  profileImageUrl?: string | null;
};

export type CharacterTreeOfStoryQueryVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type CharacterTreeOfStoryQuery = {
  __typename?: "Query";
  characterTreeOfStory:
    | { __typename: "BaseError" }
    | {
        __typename: "CharacterTreeResponse";
        characterTree: Array<{
          __typename?: "CharacterTreeNodeWithChildren";
          _id: string;
          characterId?: string | null;
          name: string;
          type: CharacterTreeNodeType;
          parentId?: string | null;
          nextNodeId?: string | null;
          prevNodeId?: string | null;
          storyId: string;
          profileImageUrl?: string | null;
          children: Array<{
            __typename?: "CharacterTreeNodeWithChildren";
            _id: string;
            characterId?: string | null;
            name: string;
            type: CharacterTreeNodeType;
            parentId?: string | null;
            nextNodeId?: string | null;
            prevNodeId?: string | null;
            storyId: string;
            profileImageUrl?: string | null;
          }>;
        }>;
      }
    | { __typename: "InvalidTreeNodeCreateInputError" };
};

export type GetMemoByStoryIdQueryVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type GetMemoByStoryIdQuery = {
  __typename?: "Query";
  getMemoByStoryId:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "MemoResponse"; id: string; storyId: string; content: string };
};

export type StoryHeaderQueryVariables = Exact<{
  input: GetStoryInput;
}>;

export type StoryHeaderQuery = {
  __typename?: "Query";
  story:
    | { __typename: "BaseError" }
    | {
        __typename: "StoryResponse";
        _id: string;
        title: string;
        category: StoryCategory;
        coverImageUrl?: string | null;
        description?: string | null;
        tags: Array<string>;
      };
};

export type CheckUserOnboardingReqQueryVariables = Exact<{ [key: string]: never }>;

export type CheckUserOnboardingReqQuery = {
  __typename: "Query";
  user:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "UserResponse"; onboarding: { __typename?: "OnboardingResponse"; isOnboardingCompleted: boolean } };
};

export type TrashCanFilePreviewQueryVariables = Exact<{
  fileId: Scalars["String"]["input"];
}>;

export type TrashCanFilePreviewQuery = {
  __typename?: "Query";
  file:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "FileResponse"; _id: string; name: string; content: string };
};

export type TrashCanFileTreeNode_FragmentFragment = {
  __typename?: "TrashCanFileTreeNode";
  _id: string;
  fileId?: string | null;
  name: string;
  type: FileTreeNodeType;
  storyId: string;
  parentId?: string | null;
  prevNodeId?: string | null;
  nextNodeId?: string | null;
  deletedAt: any;
};

export type TrashCanFileTreeOfStoryQueryVariables = Exact<{
  storyId: Scalars["String"]["input"];
}>;

export type TrashCanFileTreeOfStoryQuery = {
  __typename?: "Query";
  trashCanFileTreeOfStory:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "InternalTreeError" }
    | {
        __typename: "TrashCanFileTreeResponse";
        trashCanFileTree: Array<{
          __typename?: "TrashCanFileTreeNode";
          _id: string;
          fileId?: string | null;
          name: string;
          type: FileTreeNodeType;
          storyId: string;
          parentId?: string | null;
          prevNodeId?: string | null;
          nextNodeId?: string | null;
          deletedAt: any;
          children: Array<{
            __typename?: "TrashCanFileTreeNode";
            _id: string;
            fileId?: string | null;
            name: string;
            type: FileTreeNodeType;
            storyId: string;
            parentId?: string | null;
            prevNodeId?: string | null;
            nextNodeId?: string | null;
            deletedAt: any;
          }>;
        }>;
      };
};

export type DeleteFileTreeNodePermanentlyMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteFileTreeNodePermanentlyMutation = {
  __typename?: "Mutation";
  deleteFileTreeNodePermanently:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export type RestoreFileTreeNodeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type RestoreFileTreeNodeMutation = {
  __typename?: "Mutation";
  restoreFileTreeNode:
    | { __typename: "BaseError"; message: string; errorCode: string }
    | { __typename: "SuccessFailureResponse"; success: boolean };
};

export const FileTreeNodeWithChildren_FragmentFragmentDoc = `
    fragment FileTreeNodeWithChildren_Fragment on FileTreeNodeWithChildren {
  _id
  fileId
  name
  type
  parentId
  nextNodeId
  prevNodeId
  storyId
}
    `;
export const BaseError_FragmentFragmentDoc = `
    fragment BaseError_Fragment on BaseError {
  message
  errorCode
}
    `;
export const SearchHighlight_FragmentFragmentDoc = `
    fragment SearchHighlight_Fragment on SearchHighlightResponse {
  texts {
    type
    value
  }
}
    `;
export const FileTreeNodeWithWordCount_FragmentFragmentDoc = `
    fragment FileTreeNodeWithWordCount_Fragment on FileTreeNodeWithWordCountRes {
  _id
  fileId
  name
  type
  parentId
  nextNodeId
  prevNodeId
  storyId
  wordCount
}
    `;
export const CharacterTreeNodeWithChildren_FragmentFragmentDoc = `
    fragment CharacterTreeNodeWithChildren_Fragment on CharacterTreeNodeWithChildren {
  _id
  characterId
  name
  type
  parentId
  nextNodeId
  prevNodeId
  storyId
  profileImageUrl
}
    `;
export const TrashCanFileTreeNode_FragmentFragmentDoc = `
    fragment TrashCanFileTreeNode_Fragment on TrashCanFileTreeNode {
  _id
  fileId
  name
  type
  storyId
  parentId
  prevNodeId
  nextNodeId
  deletedAt
}
    `;
export const FileTreeOfStoryDocument = `
    query FileTreeOfStory($storyId: String!) {
  fileTreeOfStory(storyId: $storyId) {
    __typename
    ... on FileTreeResponse {
      fileTree {
        ...FileTreeNodeWithChildren_Fragment
        children {
          ...FileTreeNodeWithChildren_Fragment
        }
      }
    }
  }
}
    ${FileTreeNodeWithChildren_FragmentFragmentDoc}`;

export const useFileTreeOfStoryQuery = <TData = FileTreeOfStoryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FileTreeOfStoryQueryVariables,
  options?: UseQueryOptions<FileTreeOfStoryQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<FileTreeOfStoryQuery, TError, TData>(
    ["FileTreeOfStory", variables],
    fetcher<FileTreeOfStoryQuery, FileTreeOfStoryQueryVariables>(client, FileTreeOfStoryDocument, variables, headers),
    options
  );
};

useFileTreeOfStoryQuery.getKey = (variables: FileTreeOfStoryQueryVariables) => ["FileTreeOfStory", variables];

useFileTreeOfStoryQuery.fetcher = (
  client: GraphQLClient,
  variables: FileTreeOfStoryQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<FileTreeOfStoryQuery, FileTreeOfStoryQueryVariables>(client, FileTreeOfStoryDocument, variables, headers);

export const InitPreferenceDocument = `
    query initPreference {
  user {
    __typename
    ... on UserResponse {
      preferences {
        category
        name
        value
      }
    }
  }
}
    `;

export const useInitPreferenceQuery = <TData = InitPreferenceQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: InitPreferenceQueryVariables,
  options?: UseQueryOptions<InitPreferenceQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<InitPreferenceQuery, TError, TData>(
    variables === undefined ? ["initPreference"] : ["initPreference", variables],
    fetcher<InitPreferenceQuery, InitPreferenceQueryVariables>(client, InitPreferenceDocument, variables, headers),
    options
  );
};

useInitPreferenceQuery.getKey = (variables?: InitPreferenceQueryVariables) =>
  variables === undefined ? ["initPreference"] : ["initPreference", variables];

useInitPreferenceQuery.fetcher = (
  client: GraphQLClient,
  variables?: InitPreferenceQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<InitPreferenceQuery, InitPreferenceQueryVariables>(client, InitPreferenceDocument, variables, headers);

export const RefreshReqDocument = `
    mutation RefreshReq {
  refresh {
    ... on RefreshResponse {
      __typename
      accessToken
    }
    ... on BaseError {
      __typename
      message
      errorCode
    }
  }
}
    `;

export const useRefreshReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<RefreshReqMutation, TError, RefreshReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<RefreshReqMutation, TError, RefreshReqMutationVariables, TContext>(
    ["RefreshReq"],
    (variables?: RefreshReqMutationVariables) =>
      fetcher<RefreshReqMutation, RefreshReqMutationVariables>(client, RefreshReqDocument, variables, headers)(),
    options
  );
};

useRefreshReqMutation.getKey = () => ["RefreshReq"];

useRefreshReqMutation.fetcher = (
  client: GraphQLClient,
  variables?: RefreshReqMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<RefreshReqMutation, RefreshReqMutationVariables>(client, RefreshReqDocument, variables, headers);

export const SignInReqDocument = `
    mutation SignInReq($code: String!, $provider: String!) {
  signInOrSignUpByCode(input: {code: $code, provider: $provider}) {
    __typename
    ... on RefreshResponse {
      accessToken
      user {
        email
      }
      isNewUser
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useSignInReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<SignInReqMutation, TError, SignInReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<SignInReqMutation, TError, SignInReqMutationVariables, TContext>(
    ["SignInReq"],
    (variables?: SignInReqMutationVariables) =>
      fetcher<SignInReqMutation, SignInReqMutationVariables>(client, SignInReqDocument, variables, headers)(),
    options
  );
};

useSignInReqMutation.getKey = () => ["SignInReq"];

useSignInReqMutation.fetcher = (
  client: GraphQLClient,
  variables: SignInReqMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<SignInReqMutation, SignInReqMutationVariables>(client, SignInReqDocument, variables, headers);

export const SignOutReqDocument = `
    mutation SignOutReq {
  signOut
}
    `;

export const useSignOutReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<SignOutReqMutation, TError, SignOutReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<SignOutReqMutation, TError, SignOutReqMutationVariables, TContext>(
    ["SignOutReq"],
    (variables?: SignOutReqMutationVariables) =>
      fetcher<SignOutReqMutation, SignOutReqMutationVariables>(client, SignOutReqDocument, variables, headers)(),
    options
  );
};

useSignOutReqMutation.getKey = () => ["SignOutReq"];

useSignOutReqMutation.fetcher = (
  client: GraphQLClient,
  variables?: SignOutReqMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<SignOutReqMutation, SignOutReqMutationVariables>(client, SignOutReqDocument, variables, headers);

export const EmailSignUpReqDocument = `
    mutation EmailSignUpReq($email: String!, $password: String!) {
  emailSignUp(input: {email: $email, password: $password}) {
    __typename
    ... on RefreshResponse {
      accessToken
      user {
        email
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useEmailSignUpReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<EmailSignUpReqMutation, TError, EmailSignUpReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<EmailSignUpReqMutation, TError, EmailSignUpReqMutationVariables, TContext>(
    ["EmailSignUpReq"],
    (variables?: EmailSignUpReqMutationVariables) =>
      fetcher<EmailSignUpReqMutation, EmailSignUpReqMutationVariables>(
        client,
        EmailSignUpReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useEmailSignUpReqMutation.getKey = () => ["EmailSignUpReq"];

useEmailSignUpReqMutation.fetcher = (
  client: GraphQLClient,
  variables: EmailSignUpReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<EmailSignUpReqMutation, EmailSignUpReqMutationVariables>(client, EmailSignUpReqDocument, variables, headers);

export const EmailSignInReqDocument = `
    mutation EmailSignInReq($email: String!, $password: String!) {
  emailSignIn(input: {email: $email, password: $password}) {
    __typename
    ... on RefreshResponse {
      accessToken
      user {
        email
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useEmailSignInReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<EmailSignInReqMutation, TError, EmailSignInReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<EmailSignInReqMutation, TError, EmailSignInReqMutationVariables, TContext>(
    ["EmailSignInReq"],
    (variables?: EmailSignInReqMutationVariables) =>
      fetcher<EmailSignInReqMutation, EmailSignInReqMutationVariables>(
        client,
        EmailSignInReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useEmailSignInReqMutation.getKey = () => ["EmailSignInReq"];

useEmailSignInReqMutation.fetcher = (
  client: GraphQLClient,
  variables: EmailSignInReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<EmailSignInReqMutation, EmailSignInReqMutationVariables>(client, EmailSignInReqDocument, variables, headers);

export const VerifyEmailReqDocument = `
    mutation VerifyEmailReq($token: String!) {
  verifyEmail(input: {token: $token}) {
    __typename
    ... on RefreshResponse {
      accessToken
      user {
        email
      }
    }
    ... on NeedSignInError {
      __typename
      message
      errorCode
      data {
        email
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useVerifyEmailReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<VerifyEmailReqMutation, TError, VerifyEmailReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<VerifyEmailReqMutation, TError, VerifyEmailReqMutationVariables, TContext>(
    ["VerifyEmailReq"],
    (variables?: VerifyEmailReqMutationVariables) =>
      fetcher<VerifyEmailReqMutation, VerifyEmailReqMutationVariables>(
        client,
        VerifyEmailReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useVerifyEmailReqMutation.getKey = () => ["VerifyEmailReq"];

useVerifyEmailReqMutation.fetcher = (
  client: GraphQLClient,
  variables: VerifyEmailReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<VerifyEmailReqMutation, VerifyEmailReqMutationVariables>(client, VerifyEmailReqDocument, variables, headers);

export const ResendEmailVerificationMailReqDocument = `
    mutation ResendEmailVerificationMailReq($email: String!) {
  resendEmailVerificationMail(email: $email) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useResendEmailVerificationMailReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    ResendEmailVerificationMailReqMutation,
    TError,
    ResendEmailVerificationMailReqMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<
    ResendEmailVerificationMailReqMutation,
    TError,
    ResendEmailVerificationMailReqMutationVariables,
    TContext
  >(
    ["ResendEmailVerificationMailReq"],
    (variables?: ResendEmailVerificationMailReqMutationVariables) =>
      fetcher<ResendEmailVerificationMailReqMutation, ResendEmailVerificationMailReqMutationVariables>(
        client,
        ResendEmailVerificationMailReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useResendEmailVerificationMailReqMutation.getKey = () => ["ResendEmailVerificationMailReq"];

useResendEmailVerificationMailReqMutation.fetcher = (
  client: GraphQLClient,
  variables: ResendEmailVerificationMailReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<ResendEmailVerificationMailReqMutation, ResendEmailVerificationMailReqMutationVariables>(
    client,
    ResendEmailVerificationMailReqDocument,
    variables,
    headers
  );

export const SendResetPasswordMailReqDocument = `
    mutation SendResetPasswordMailReq($email: String!) {
  sendResetPasswordMail(email: $email) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useSendResetPasswordMailReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    SendResetPasswordMailReqMutation,
    TError,
    SendResetPasswordMailReqMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<SendResetPasswordMailReqMutation, TError, SendResetPasswordMailReqMutationVariables, TContext>(
    ["SendResetPasswordMailReq"],
    (variables?: SendResetPasswordMailReqMutationVariables) =>
      fetcher<SendResetPasswordMailReqMutation, SendResetPasswordMailReqMutationVariables>(
        client,
        SendResetPasswordMailReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useSendResetPasswordMailReqMutation.getKey = () => ["SendResetPasswordMailReq"];

useSendResetPasswordMailReqMutation.fetcher = (
  client: GraphQLClient,
  variables: SendResetPasswordMailReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<SendResetPasswordMailReqMutation, SendResetPasswordMailReqMutationVariables>(
    client,
    SendResetPasswordMailReqDocument,
    variables,
    headers
  );

export const ResetPasswordReqDocument = `
    mutation ResetPasswordReq($token: String!, $newPassword: String!) {
  resetPassword(input: {token: $token, newPassword: $newPassword}) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useResetPasswordReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<ResetPasswordReqMutation, TError, ResetPasswordReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<ResetPasswordReqMutation, TError, ResetPasswordReqMutationVariables, TContext>(
    ["ResetPasswordReq"],
    (variables?: ResetPasswordReqMutationVariables) =>
      fetcher<ResetPasswordReqMutation, ResetPasswordReqMutationVariables>(
        client,
        ResetPasswordReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useResetPasswordReqMutation.getKey = () => ["ResetPasswordReq"];

useResetPasswordReqMutation.fetcher = (
  client: GraphQLClient,
  variables: ResetPasswordReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<ResetPasswordReqMutation, ResetPasswordReqMutationVariables>(
    client,
    ResetPasswordReqDocument,
    variables,
    headers
  );

export const UpdateCharacterProfileUrlDocument = `
    mutation UpdateCharacterProfileUrl($input: UpdateCharacterProfileUrlInput!) {
  updateCharacterProfileUrl(input: $input) {
    __typename
    ... on CharacterResponse {
      _id
      name
      profileImageUrl
      nodeId
      storyId
      descriptions {
        title
        content
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateCharacterProfileUrlMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateCharacterProfileUrlMutation,
    TError,
    UpdateCharacterProfileUrlMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateCharacterProfileUrlMutation, TError, UpdateCharacterProfileUrlMutationVariables, TContext>(
    ["UpdateCharacterProfileUrl"],
    (variables?: UpdateCharacterProfileUrlMutationVariables) =>
      fetcher<UpdateCharacterProfileUrlMutation, UpdateCharacterProfileUrlMutationVariables>(
        client,
        UpdateCharacterProfileUrlDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateCharacterProfileUrlMutation.getKey = () => ["UpdateCharacterProfileUrl"];

useUpdateCharacterProfileUrlMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateCharacterProfileUrlMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateCharacterProfileUrlMutation, UpdateCharacterProfileUrlMutationVariables>(
    client,
    UpdateCharacterProfileUrlDocument,
    variables,
    headers
  );

export const UpdateCharacterDocument = `
    mutation UpdateCharacter($input: UpdateCharacterInput!) {
  updateCharacter(input: $input) {
    __typename
    ... on CharacterResponse {
      _id
      name
      profileImageUrl
      nodeId
      storyId
      descriptions {
        title
        content
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateCharacterMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateCharacterMutation, TError, UpdateCharacterMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateCharacterMutation, TError, UpdateCharacterMutationVariables, TContext>(
    ["UpdateCharacter"],
    (variables?: UpdateCharacterMutationVariables) =>
      fetcher<UpdateCharacterMutation, UpdateCharacterMutationVariables>(
        client,
        UpdateCharacterDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateCharacterMutation.getKey = () => ["UpdateCharacter"];

useUpdateCharacterMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateCharacterMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateCharacterMutation, UpdateCharacterMutationVariables>(
    client,
    UpdateCharacterDocument,
    variables,
    headers
  );

export const CreateCharacterTreeNodeDocument = `
    mutation CreateCharacterTreeNode($input: CreateCharacterTreeNodeInput!) {
  createCharacterTreeNode(input: $input) {
    __typename
    ... on CharacterTreeNodeResponse {
      _id
      name
      characterId
      type
      prevNodeId
      nextNodeId
      parentId
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCreateCharacterTreeNodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateCharacterTreeNodeMutation,
    TError,
    CreateCharacterTreeNodeMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<CreateCharacterTreeNodeMutation, TError, CreateCharacterTreeNodeMutationVariables, TContext>(
    ["CreateCharacterTreeNode"],
    (variables?: CreateCharacterTreeNodeMutationVariables) =>
      fetcher<CreateCharacterTreeNodeMutation, CreateCharacterTreeNodeMutationVariables>(
        client,
        CreateCharacterTreeNodeDocument,
        variables,
        headers
      )(),
    options
  );
};

useCreateCharacterTreeNodeMutation.getKey = () => ["CreateCharacterTreeNode"];

useCreateCharacterTreeNodeMutation.fetcher = (
  client: GraphQLClient,
  variables: CreateCharacterTreeNodeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CreateCharacterTreeNodeMutation, CreateCharacterTreeNodeMutationVariables>(
    client,
    CreateCharacterTreeNodeDocument,
    variables,
    headers
  );

export const CreateCharacterTreeNodeWithTemplateDocument = `
    mutation CreateCharacterTreeNodeWithTemplate($input: CreateCharacterTreeNodeWithTemplateInput!) {
  createCharacterTreeNodeWithTemplate(input: $input) {
    __typename
    ... on CharacterTreeNodeResponse {
      _id
      name
      characterId
      type
      prevNodeId
      nextNodeId
      parentId
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCreateCharacterTreeNodeWithTemplateMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateCharacterTreeNodeWithTemplateMutation,
    TError,
    CreateCharacterTreeNodeWithTemplateMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<
    CreateCharacterTreeNodeWithTemplateMutation,
    TError,
    CreateCharacterTreeNodeWithTemplateMutationVariables,
    TContext
  >(
    ["CreateCharacterTreeNodeWithTemplate"],
    (variables?: CreateCharacterTreeNodeWithTemplateMutationVariables) =>
      fetcher<CreateCharacterTreeNodeWithTemplateMutation, CreateCharacterTreeNodeWithTemplateMutationVariables>(
        client,
        CreateCharacterTreeNodeWithTemplateDocument,
        variables,
        headers
      )(),
    options
  );
};

useCreateCharacterTreeNodeWithTemplateMutation.getKey = () => ["CreateCharacterTreeNodeWithTemplate"];

useCreateCharacterTreeNodeWithTemplateMutation.fetcher = (
  client: GraphQLClient,
  variables: CreateCharacterTreeNodeWithTemplateMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CreateCharacterTreeNodeWithTemplateMutation, CreateCharacterTreeNodeWithTemplateMutationVariables>(
    client,
    CreateCharacterTreeNodeWithTemplateDocument,
    variables,
    headers
  );

export const UpdateCharacterTreeNodeAttributeDocument = `
    mutation UpdateCharacterTreeNodeAttribute($input: UpdateTreeNodeAttributeInput!) {
  updateCharacterTreeNodeAttribute(input: $input) {
    __typename
    ... on CharacterTreeNodeResponse {
      _id
      name
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateCharacterTreeNodeAttributeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateCharacterTreeNodeAttributeMutation,
    TError,
    UpdateCharacterTreeNodeAttributeMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<
    UpdateCharacterTreeNodeAttributeMutation,
    TError,
    UpdateCharacterTreeNodeAttributeMutationVariables,
    TContext
  >(
    ["UpdateCharacterTreeNodeAttribute"],
    (variables?: UpdateCharacterTreeNodeAttributeMutationVariables) =>
      fetcher<UpdateCharacterTreeNodeAttributeMutation, UpdateCharacterTreeNodeAttributeMutationVariables>(
        client,
        UpdateCharacterTreeNodeAttributeDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateCharacterTreeNodeAttributeMutation.getKey = () => ["UpdateCharacterTreeNodeAttribute"];

useUpdateCharacterTreeNodeAttributeMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateCharacterTreeNodeAttributeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateCharacterTreeNodeAttributeMutation, UpdateCharacterTreeNodeAttributeMutationVariables>(
    client,
    UpdateCharacterTreeNodeAttributeDocument,
    variables,
    headers
  );

export const UpdateCharacterTreeDocument = `
    mutation UpdateCharacterTree($input: UpdateTreeInput!) {
  updateCharacterTree(input: $input) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateCharacterTreeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateCharacterTreeMutation, TError, UpdateCharacterTreeMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateCharacterTreeMutation, TError, UpdateCharacterTreeMutationVariables, TContext>(
    ["UpdateCharacterTree"],
    (variables?: UpdateCharacterTreeMutationVariables) =>
      fetcher<UpdateCharacterTreeMutation, UpdateCharacterTreeMutationVariables>(
        client,
        UpdateCharacterTreeDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateCharacterTreeMutation.getKey = () => ["UpdateCharacterTree"];

useUpdateCharacterTreeMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateCharacterTreeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateCharacterTreeMutation, UpdateCharacterTreeMutationVariables>(
    client,
    UpdateCharacterTreeDocument,
    variables,
    headers
  );

export const DeleteCharacterTreeNodeDocument = `
    mutation DeleteCharacterTreeNode($id: String!) {
  deleteCharacterTreeNode(id: $id) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useDeleteCharacterTreeNodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteCharacterTreeNodeMutation,
    TError,
    DeleteCharacterTreeNodeMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<DeleteCharacterTreeNodeMutation, TError, DeleteCharacterTreeNodeMutationVariables, TContext>(
    ["DeleteCharacterTreeNode"],
    (variables?: DeleteCharacterTreeNodeMutationVariables) =>
      fetcher<DeleteCharacterTreeNodeMutation, DeleteCharacterTreeNodeMutationVariables>(
        client,
        DeleteCharacterTreeNodeDocument,
        variables,
        headers
      )(),
    options
  );
};

useDeleteCharacterTreeNodeMutation.getKey = () => ["DeleteCharacterTreeNode"];

useDeleteCharacterTreeNodeMutation.fetcher = (
  client: GraphQLClient,
  variables: DeleteCharacterTreeNodeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<DeleteCharacterTreeNodeMutation, DeleteCharacterTreeNodeMutationVariables>(
    client,
    DeleteCharacterTreeNodeDocument,
    variables,
    headers
  );

export const DuplicateCharacterTreeNodeDocument = `
    mutation DuplicateCharacterTreeNode($id: String!) {
  duplicateCharacterTreeNode(id: $id) {
    __typename
    ... on CharacterTreeNodeResponse {
      _id
      name
      characterId
      type
      prevNodeId
      nextNodeId
      parentId
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useDuplicateCharacterTreeNodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DuplicateCharacterTreeNodeMutation,
    TError,
    DuplicateCharacterTreeNodeMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<DuplicateCharacterTreeNodeMutation, TError, DuplicateCharacterTreeNodeMutationVariables, TContext>(
    ["DuplicateCharacterTreeNode"],
    (variables?: DuplicateCharacterTreeNodeMutationVariables) =>
      fetcher<DuplicateCharacterTreeNodeMutation, DuplicateCharacterTreeNodeMutationVariables>(
        client,
        DuplicateCharacterTreeNodeDocument,
        variables,
        headers
      )(),
    options
  );
};

useDuplicateCharacterTreeNodeMutation.getKey = () => ["DuplicateCharacterTreeNode"];

useDuplicateCharacterTreeNodeMutation.fetcher = (
  client: GraphQLClient,
  variables: DuplicateCharacterTreeNodeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<DuplicateCharacterTreeNodeMutation, DuplicateCharacterTreeNodeMutationVariables>(
    client,
    DuplicateCharacterTreeNodeDocument,
    variables,
    headers
  );

export const UpdateFileContentDocument = `
    mutation UpdateFileContent($input: UpdateFileContentInput!) {
  updateFileContent(input: $input) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
  }
}
    `;

export const useUpdateFileContentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateFileContentMutation, TError, UpdateFileContentMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateFileContentMutation, TError, UpdateFileContentMutationVariables, TContext>(
    ["UpdateFileContent"],
    (variables?: UpdateFileContentMutationVariables) =>
      fetcher<UpdateFileContentMutation, UpdateFileContentMutationVariables>(
        client,
        UpdateFileContentDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateFileContentMutation.getKey = () => ["UpdateFileContent"];

useUpdateFileContentMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateFileContentMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateFileContentMutation, UpdateFileContentMutationVariables>(
    client,
    UpdateFileContentDocument,
    variables,
    headers
  );

export const UpdateFilePublicStatusDocument = `
    mutation UpdateFilePublicStatus($input: UpdateFilePublicStatusInput!) {
  updateFilePublicStatus(input: $input) {
    __typename
    ... on FileResponse {
      _id
      name
      isPublic
      fileTreeNodeId
    }
  }
}
    `;

export const useUpdateFilePublicStatusMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateFilePublicStatusMutation,
    TError,
    UpdateFilePublicStatusMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateFilePublicStatusMutation, TError, UpdateFilePublicStatusMutationVariables, TContext>(
    ["UpdateFilePublicStatus"],
    (variables?: UpdateFilePublicStatusMutationVariables) =>
      fetcher<UpdateFilePublicStatusMutation, UpdateFilePublicStatusMutationVariables>(
        client,
        UpdateFilePublicStatusDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateFilePublicStatusMutation.getKey = () => ["UpdateFilePublicStatus"];

useUpdateFilePublicStatusMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateFilePublicStatusMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateFilePublicStatusMutation, UpdateFilePublicStatusMutationVariables>(
    client,
    UpdateFilePublicStatusDocument,
    variables,
    headers
  );

export const ExportFilesDocument = `
    mutation ExportFiles($input: ExportFilesInput!) {
  exportFiles(input: $input) {
    __typename
    ... on ExportFilesResponse {
      url
    }
  }
}
    `;

export const useExportFilesMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<ExportFilesMutation, TError, ExportFilesMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<ExportFilesMutation, TError, ExportFilesMutationVariables, TContext>(
    ["ExportFiles"],
    (variables?: ExportFilesMutationVariables) =>
      fetcher<ExportFilesMutation, ExportFilesMutationVariables>(client, ExportFilesDocument, variables, headers)(),
    options
  );
};

useExportFilesMutation.getKey = () => ["ExportFiles"];

useExportFilesMutation.fetcher = (
  client: GraphQLClient,
  variables: ExportFilesMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<ExportFilesMutation, ExportFilesMutationVariables>(client, ExportFilesDocument, variables, headers);

export const UploadEditorImageDocument = `
    mutation UploadEditorImage($input: UploadEditorImageInput!) {
  uploadEditorImage(input: $input) {
    __typename
    ... on UploadEditorImageResponse {
      url
    }
  }
}
    `;

export const useUploadEditorImageMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UploadEditorImageMutation, TError, UploadEditorImageMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UploadEditorImageMutation, TError, UploadEditorImageMutationVariables, TContext>(
    ["UploadEditorImage"],
    (variables?: UploadEditorImageMutationVariables) =>
      fetcher<UploadEditorImageMutation, UploadEditorImageMutationVariables>(
        client,
        UploadEditorImageDocument,
        variables,
        headers
      )(),
    options
  );
};

useUploadEditorImageMutation.getKey = () => ["UploadEditorImage"];

useUploadEditorImageMutation.fetcher = (
  client: GraphQLClient,
  variables: UploadEditorImageMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UploadEditorImageMutation, UploadEditorImageMutationVariables>(
    client,
    UploadEditorImageDocument,
    variables,
    headers
  );

export const CreateFileTreeNodeReqDocument = `
    mutation CreateFileTreeNodeReq($input: CreateFileTreeNodeInput!) {
  createFileTreeNode(input: $input) {
    __typename
    ... on FileTreeNodeResponse {
      _id
      fileId
      name
      type
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCreateFileTreeNodeReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CreateFileTreeNodeReqMutation, TError, CreateFileTreeNodeReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<CreateFileTreeNodeReqMutation, TError, CreateFileTreeNodeReqMutationVariables, TContext>(
    ["CreateFileTreeNodeReq"],
    (variables?: CreateFileTreeNodeReqMutationVariables) =>
      fetcher<CreateFileTreeNodeReqMutation, CreateFileTreeNodeReqMutationVariables>(
        client,
        CreateFileTreeNodeReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useCreateFileTreeNodeReqMutation.getKey = () => ["CreateFileTreeNodeReq"];

useCreateFileTreeNodeReqMutation.fetcher = (
  client: GraphQLClient,
  variables: CreateFileTreeNodeReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CreateFileTreeNodeReqMutation, CreateFileTreeNodeReqMutationVariables>(
    client,
    CreateFileTreeNodeReqDocument,
    variables,
    headers
  );

export const UpdateFileTreeNodeAttributeDocument = `
    mutation UpdateFileTreeNodeAttribute($input: UpdateTreeNodeAttributeInput!) {
  updateFileTreeNodeAttribute(input: $input) {
    __typename
    ... on FileTreeNodeResponse {
      _id
      name
      fileId
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateFileTreeNodeAttributeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateFileTreeNodeAttributeMutation,
    TError,
    UpdateFileTreeNodeAttributeMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<
    UpdateFileTreeNodeAttributeMutation,
    TError,
    UpdateFileTreeNodeAttributeMutationVariables,
    TContext
  >(
    ["UpdateFileTreeNodeAttribute"],
    (variables?: UpdateFileTreeNodeAttributeMutationVariables) =>
      fetcher<UpdateFileTreeNodeAttributeMutation, UpdateFileTreeNodeAttributeMutationVariables>(
        client,
        UpdateFileTreeNodeAttributeDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateFileTreeNodeAttributeMutation.getKey = () => ["UpdateFileTreeNodeAttribute"];

useUpdateFileTreeNodeAttributeMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateFileTreeNodeAttributeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateFileTreeNodeAttributeMutation, UpdateFileTreeNodeAttributeMutationVariables>(
    client,
    UpdateFileTreeNodeAttributeDocument,
    variables,
    headers
  );

export const UpdateFileTreeDocument = `
    mutation UpdateFileTree($input: UpdateTreeInput!) {
  updateFileTree(input: $input) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateFileTreeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateFileTreeMutation, TError, UpdateFileTreeMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateFileTreeMutation, TError, UpdateFileTreeMutationVariables, TContext>(
    ["UpdateFileTree"],
    (variables?: UpdateFileTreeMutationVariables) =>
      fetcher<UpdateFileTreeMutation, UpdateFileTreeMutationVariables>(
        client,
        UpdateFileTreeDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateFileTreeMutation.getKey = () => ["UpdateFileTree"];

useUpdateFileTreeMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateFileTreeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateFileTreeMutation, UpdateFileTreeMutationVariables>(client, UpdateFileTreeDocument, variables, headers);

export const DuplicateFileTreeNodeDocument = `
    mutation DuplicateFileTreeNode($id: String!) {
  duplicateFileTreeNode(id: $id) {
    __typename
    ... on FileTreeNodeResponse {
      _id
      fileId
      name
      type
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useDuplicateFileTreeNodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DuplicateFileTreeNodeMutation, TError, DuplicateFileTreeNodeMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<DuplicateFileTreeNodeMutation, TError, DuplicateFileTreeNodeMutationVariables, TContext>(
    ["DuplicateFileTreeNode"],
    (variables?: DuplicateFileTreeNodeMutationVariables) =>
      fetcher<DuplicateFileTreeNodeMutation, DuplicateFileTreeNodeMutationVariables>(
        client,
        DuplicateFileTreeNodeDocument,
        variables,
        headers
      )(),
    options
  );
};

useDuplicateFileTreeNodeMutation.getKey = () => ["DuplicateFileTreeNode"];

useDuplicateFileTreeNodeMutation.fetcher = (
  client: GraphQLClient,
  variables: DuplicateFileTreeNodeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<DuplicateFileTreeNodeMutation, DuplicateFileTreeNodeMutationVariables>(
    client,
    DuplicateFileTreeNodeDocument,
    variables,
    headers
  );

export const DeleteFileTreeNodeDocument = `
    mutation DeleteFileTreeNode($id: String!) {
  deleteFileTreeNode(id: $id) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useDeleteFileTreeNodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteFileTreeNodeMutation, TError, DeleteFileTreeNodeMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<DeleteFileTreeNodeMutation, TError, DeleteFileTreeNodeMutationVariables, TContext>(
    ["DeleteFileTreeNode"],
    (variables?: DeleteFileTreeNodeMutationVariables) =>
      fetcher<DeleteFileTreeNodeMutation, DeleteFileTreeNodeMutationVariables>(
        client,
        DeleteFileTreeNodeDocument,
        variables,
        headers
      )(),
    options
  );
};

useDeleteFileTreeNodeMutation.getKey = () => ["DeleteFileTreeNode"];

useDeleteFileTreeNodeMutation.fetcher = (
  client: GraphQLClient,
  variables: DeleteFileTreeNodeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<DeleteFileTreeNodeMutation, DeleteFileTreeNodeMutationVariables>(
    client,
    DeleteFileTreeNodeDocument,
    variables,
    headers
  );

export const CreateHistoryDocument = `
    mutation CreateHistory($input: CreateHistoryInput!) {
  createHistory(input: $input) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
  }
}
    `;

export const useCreateHistoryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<CreateHistoryMutation, TError, CreateHistoryMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<CreateHistoryMutation, TError, CreateHistoryMutationVariables, TContext>(
    ["CreateHistory"],
    (variables?: CreateHistoryMutationVariables) =>
      fetcher<CreateHistoryMutation, CreateHistoryMutationVariables>(
        client,
        CreateHistoryDocument,
        variables,
        headers
      )(),
    options
  );
};

useCreateHistoryMutation.getKey = () => ["CreateHistory"];

useCreateHistoryMutation.fetcher = (
  client: GraphQLClient,
  variables: CreateHistoryMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<CreateHistoryMutation, CreateHistoryMutationVariables>(client, CreateHistoryDocument, variables, headers);

export const UpdateHistoryTitleDocument = `
    mutation UpdateHistoryTitle($input: UpdateHistoryTitleInput!) {
  updateHistoryTitle(input: $input) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
  }
}
    `;

export const useUpdateHistoryTitleMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateHistoryTitleMutation, TError, UpdateHistoryTitleMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateHistoryTitleMutation, TError, UpdateHistoryTitleMutationVariables, TContext>(
    ["UpdateHistoryTitle"],
    (variables?: UpdateHistoryTitleMutationVariables) =>
      fetcher<UpdateHistoryTitleMutation, UpdateHistoryTitleMutationVariables>(
        client,
        UpdateHistoryTitleDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateHistoryTitleMutation.getKey = () => ["UpdateHistoryTitle"];

useUpdateHistoryTitleMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateHistoryTitleMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateHistoryTitleMutation, UpdateHistoryTitleMutationVariables>(
    client,
    UpdateHistoryTitleDocument,
    variables,
    headers
  );

export const DeleteHistoryDocument = `
    mutation DeleteHistory($id: String!) {
  deleteHistory(historyId: $id) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
  }
}
    `;

export const useDeleteHistoryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteHistoryMutation, TError, DeleteHistoryMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<DeleteHistoryMutation, TError, DeleteHistoryMutationVariables, TContext>(
    ["DeleteHistory"],
    (variables?: DeleteHistoryMutationVariables) =>
      fetcher<DeleteHistoryMutation, DeleteHistoryMutationVariables>(
        client,
        DeleteHistoryDocument,
        variables,
        headers
      )(),
    options
  );
};

useDeleteHistoryMutation.getKey = () => ["DeleteHistory"];

useDeleteHistoryMutation.fetcher = (
  client: GraphQLClient,
  variables: DeleteHistoryMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<DeleteHistoryMutation, DeleteHistoryMutationVariables>(client, DeleteHistoryDocument, variables, headers);

export const RestoreHistoryDocument = `
    mutation RestoreHistory($input: RestoreHistoryInput!) {
  restoreHistory(input: $input) {
    __typename
    ... on FileResponse {
      _id
      content
    }
  }
}
    `;

export const useRestoreHistoryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<RestoreHistoryMutation, TError, RestoreHistoryMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<RestoreHistoryMutation, TError, RestoreHistoryMutationVariables, TContext>(
    ["RestoreHistory"],
    (variables?: RestoreHistoryMutationVariables) =>
      fetcher<RestoreHistoryMutation, RestoreHistoryMutationVariables>(
        client,
        RestoreHistoryDocument,
        variables,
        headers
      )(),
    options
  );
};

useRestoreHistoryMutation.getKey = () => ["RestoreHistory"];

useRestoreHistoryMutation.fetcher = (
  client: GraphQLClient,
  variables: RestoreHistoryMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<RestoreHistoryMutation, RestoreHistoryMutationVariables>(client, RestoreHistoryDocument, variables, headers);

export const UpdateMemoDocument = `
    mutation UpdateMemo($input: UpdateMemoInput!) {
  updateMemo(input: $input) {
    __typename
    ... on MemoResponse {
      id
      storyId
      content
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateMemoMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateMemoMutation, TError, UpdateMemoMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateMemoMutation, TError, UpdateMemoMutationVariables, TContext>(
    ["UpdateMemo"],
    (variables?: UpdateMemoMutationVariables) =>
      fetcher<UpdateMemoMutation, UpdateMemoMutationVariables>(client, UpdateMemoDocument, variables, headers)(),
    options
  );
};

useUpdateMemoMutation.getKey = () => ["UpdateMemo"];

useUpdateMemoMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateMemoMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<UpdateMemoMutation, UpdateMemoMutationVariables>(client, UpdateMemoDocument, variables, headers);

export const FileSearchDocument = `
    query FileSearch($input: FileSearchInput!) {
  fileSearch(input: $input) {
    __typename
    ... on FileSearchResultsResponse {
      searchResults {
        _id
        name
        highlights {
          ...SearchHighlight_Fragment
        }
      }
      hitCount
    }
  }
}
    ${SearchHighlight_FragmentFragmentDoc}`;

export const useFileSearchQuery = <TData = FileSearchQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FileSearchQueryVariables,
  options?: UseQueryOptions<FileSearchQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<FileSearchQuery, TError, TData>(
    ["FileSearch", variables],
    fetcher<FileSearchQuery, FileSearchQueryVariables>(client, FileSearchDocument, variables, headers),
    options
  );
};

useFileSearchQuery.getKey = (variables: FileSearchQueryVariables) => ["FileSearch", variables];

useFileSearchQuery.fetcher = (
  client: GraphQLClient,
  variables: FileSearchQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<FileSearchQuery, FileSearchQueryVariables>(client, FileSearchDocument, variables, headers);

export const StoryReqDocument = `
    query StoryReq($authorId: String!, $storyId: String!) {
  story(input: {storyId: $storyId}) {
    __typename
    ... on StoryResponse {
      _id
      authorId
      category
      coverImageUrl
      description
      tags
      title
      updatedAt
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useStoryReqQuery = <TData = StoryReqQuery, TError = unknown>(
  client: GraphQLClient,
  variables: StoryReqQueryVariables,
  options?: UseQueryOptions<StoryReqQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<StoryReqQuery, TError, TData>(
    ["StoryReq", variables],
    fetcher<StoryReqQuery, StoryReqQueryVariables>(client, StoryReqDocument, variables, headers),
    options
  );
};

useStoryReqQuery.getKey = (variables: StoryReqQueryVariables) => ["StoryReq", variables];

useStoryReqQuery.fetcher = (
  client: GraphQLClient,
  variables: StoryReqQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<StoryReqQuery, StoryReqQueryVariables>(client, StoryReqDocument, variables, headers);

export const StartNewStoryReqDocument = `
    mutation StartNewStoryReq($input: StartNewStoryInput!) {
  startNewStory(input: $input) {
    __typename
    ... on NewStoryResponse {
      story {
        _id
        authorId
        category
        coverImageUrl
        description
        tags
        title
        updatedAt
      }
      fileTreeNode {
        fileId
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useStartNewStoryReqMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<StartNewStoryReqMutation, TError, StartNewStoryReqMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<StartNewStoryReqMutation, TError, StartNewStoryReqMutationVariables, TContext>(
    ["StartNewStoryReq"],
    (variables?: StartNewStoryReqMutationVariables) =>
      fetcher<StartNewStoryReqMutation, StartNewStoryReqMutationVariables>(
        client,
        StartNewStoryReqDocument,
        variables,
        headers
      )(),
    options
  );
};

useStartNewStoryReqMutation.getKey = () => ["StartNewStoryReq"];

useStartNewStoryReqMutation.fetcher = (
  client: GraphQLClient,
  variables: StartNewStoryReqMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<StartNewStoryReqMutation, StartNewStoryReqMutationVariables>(
    client,
    StartNewStoryReqDocument,
    variables,
    headers
  );

export const UpdateStoryDocument = `
    mutation UpdateStory($input: UpdateStoryInput!) {
  updateStory(input: $input) {
    __typename
    ... on StoryResponse {
      _id
      authorId
      category
      coverImageUrl
      description
      tags
      title
      updatedAt
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateStoryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateStoryMutation, TError, UpdateStoryMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateStoryMutation, TError, UpdateStoryMutationVariables, TContext>(
    ["UpdateStory"],
    (variables?: UpdateStoryMutationVariables) =>
      fetcher<UpdateStoryMutation, UpdateStoryMutationVariables>(client, UpdateStoryDocument, variables, headers)(),
    options
  );
};

useUpdateStoryMutation.getKey = () => ["UpdateStory"];

useUpdateStoryMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateStoryMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<UpdateStoryMutation, UpdateStoryMutationVariables>(client, UpdateStoryDocument, variables, headers);

export const ToggleStoryPinDocument = `
    mutation ToggleStoryPin($storyId: String!) {
  toggleStoryPin(storyId: $storyId) {
    __typename
    ... on StoryResponse {
      _id
      authorId
      category
      coverImageUrl
      description
      tags
      title
      updatedAt
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useToggleStoryPinMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<ToggleStoryPinMutation, TError, ToggleStoryPinMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<ToggleStoryPinMutation, TError, ToggleStoryPinMutationVariables, TContext>(
    ["ToggleStoryPin"],
    (variables?: ToggleStoryPinMutationVariables) =>
      fetcher<ToggleStoryPinMutation, ToggleStoryPinMutationVariables>(
        client,
        ToggleStoryPinDocument,
        variables,
        headers
      )(),
    options
  );
};

useToggleStoryPinMutation.getKey = () => ["ToggleStoryPin"];

useToggleStoryPinMutation.fetcher = (
  client: GraphQLClient,
  variables: ToggleStoryPinMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<ToggleStoryPinMutation, ToggleStoryPinMutationVariables>(client, ToggleStoryPinDocument, variables, headers);

export const DeleteStoryDocument = `
    mutation DeleteStory($storyId: String!) {
  deleteStory(storyId: $storyId) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
  }
}
    `;

export const useDeleteStoryMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<DeleteStoryMutation, TError, DeleteStoryMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<DeleteStoryMutation, TError, DeleteStoryMutationVariables, TContext>(
    ["DeleteStory"],
    (variables?: DeleteStoryMutationVariables) =>
      fetcher<DeleteStoryMutation, DeleteStoryMutationVariables>(client, DeleteStoryDocument, variables, headers)(),
    options
  );
};

useDeleteStoryMutation.getKey = () => ["DeleteStory"];

useDeleteStoryMutation.fetcher = (
  client: GraphQLClient,
  variables: DeleteStoryMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<DeleteStoryMutation, DeleteStoryMutationVariables>(client, DeleteStoryDocument, variables, headers);

export const UserReqDocument = `
    query UserReq {
  user {
    __typename
    ... on UserResponse {
      _id
      email
      isEmailVerified
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUserReqQuery = <TData = UserReqQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: UserReqQueryVariables,
  options?: UseQueryOptions<UserReqQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<UserReqQuery, TError, TData>(
    variables === undefined ? ["UserReq"] : ["UserReq", variables],
    fetcher<UserReqQuery, UserReqQueryVariables>(client, UserReqDocument, variables, headers),
    options
  );
};

useUserReqQuery.getKey = (variables?: UserReqQueryVariables) =>
  variables === undefined ? ["UserReq"] : ["UserReq", variables];

useUserReqQuery.fetcher = (
  client: GraphQLClient,
  variables?: UserReqQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<UserReqQuery, UserReqQueryVariables>(client, UserReqDocument, variables, headers);

export const UpdatePreferencesDocument = `
    mutation updatePreferences($input: [UpdatePreferencesInput!]!) {
  updatePreferences(input: $input) {
    __typename
    ... on UserResponse {
      preferences {
        category
        name
        value
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdatePreferencesMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdatePreferencesMutation, TError, UpdatePreferencesMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdatePreferencesMutation, TError, UpdatePreferencesMutationVariables, TContext>(
    ["updatePreferences"],
    (variables?: UpdatePreferencesMutationVariables) =>
      fetcher<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(
        client,
        UpdatePreferencesDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdatePreferencesMutation.getKey = () => ["updatePreferences"];

useUpdatePreferencesMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdatePreferencesMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(
    client,
    UpdatePreferencesDocument,
    variables,
    headers
  );

export const SubmitSurveyDocument = `
    mutation submitSurvey($input: SubmitSurveyInput!) {
  submitSurvey(input: $input) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useSubmitSurveyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<SubmitSurveyMutation, TError, SubmitSurveyMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<SubmitSurveyMutation, TError, SubmitSurveyMutationVariables, TContext>(
    ["submitSurvey"],
    (variables?: SubmitSurveyMutationVariables) =>
      fetcher<SubmitSurveyMutation, SubmitSurveyMutationVariables>(client, SubmitSurveyDocument, variables, headers)(),
    options
  );
};

useSubmitSurveyMutation.getKey = () => ["submitSurvey"];

useSubmitSurveyMutation.fetcher = (
  client: GraphQLClient,
  variables: SubmitSurveyMutationVariables,
  headers?: RequestInit["headers"]
) => fetcher<SubmitSurveyMutation, SubmitSurveyMutationVariables>(client, SubmitSurveyDocument, variables, headers);

export const CompleteOnboardingTourDocument = `
    mutation completeOnboardingTour {
  completeOnboardingTour {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCompleteOnboardingTourMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CompleteOnboardingTourMutation,
    TError,
    CompleteOnboardingTourMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<CompleteOnboardingTourMutation, TError, CompleteOnboardingTourMutationVariables, TContext>(
    ["completeOnboardingTour"],
    (variables?: CompleteOnboardingTourMutationVariables) =>
      fetcher<CompleteOnboardingTourMutation, CompleteOnboardingTourMutationVariables>(
        client,
        CompleteOnboardingTourDocument,
        variables,
        headers
      )(),
    options
  );
};

useCompleteOnboardingTourMutation.getKey = () => ["completeOnboardingTour"];

useCompleteOnboardingTourMutation.fetcher = (
  client: GraphQLClient,
  variables?: CompleteOnboardingTourMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CompleteOnboardingTourMutation, CompleteOnboardingTourMutationVariables>(
    client,
    CompleteOnboardingTourDocument,
    variables,
    headers
  );

export const StoriesReqDocument = `
    query StoriesReq {
  stories {
    __typename
    ... on StoriesResponse {
      stories {
        _id
        authorId
        category
        coverImageUrl
        description
        tags
        title
        updatedAt
        pinnedAt
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useStoriesReqQuery = <TData = StoriesReqQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: StoriesReqQueryVariables,
  options?: UseQueryOptions<StoriesReqQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<StoriesReqQuery, TError, TData>(
    variables === undefined ? ["StoriesReq"] : ["StoriesReq", variables],
    fetcher<StoriesReqQuery, StoriesReqQueryVariables>(client, StoriesReqDocument, variables, headers),
    options
  );
};

useStoriesReqQuery.getKey = (variables?: StoriesReqQueryVariables) =>
  variables === undefined ? ["StoriesReq"] : ["StoriesReq", variables];

useStoriesReqQuery.fetcher = (
  client: GraphQLClient,
  variables?: StoriesReqQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<StoriesReqQuery, StoriesReqQueryVariables>(client, StoriesReqDocument, variables, headers);

export const CheckUserSurveyReqDocument = `
    query CheckUserSurveyReq {
  __typename
  user {
    __typename
    ... on UserResponse {
      firstStoryCreated
      onboarding {
        isSurveyCompleted
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCheckUserSurveyReqQuery = <TData = CheckUserSurveyReqQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: CheckUserSurveyReqQueryVariables,
  options?: UseQueryOptions<CheckUserSurveyReqQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<CheckUserSurveyReqQuery, TError, TData>(
    variables === undefined ? ["CheckUserSurveyReq"] : ["CheckUserSurveyReq", variables],
    fetcher<CheckUserSurveyReqQuery, CheckUserSurveyReqQueryVariables>(
      client,
      CheckUserSurveyReqDocument,
      variables,
      headers
    ),
    options
  );
};

useCheckUserSurveyReqQuery.getKey = (variables?: CheckUserSurveyReqQueryVariables) =>
  variables === undefined ? ["CheckUserSurveyReq"] : ["CheckUserSurveyReq", variables];

useCheckUserSurveyReqQuery.fetcher = (
  client: GraphQLClient,
  variables?: CheckUserSurveyReqQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CheckUserSurveyReqQuery, CheckUserSurveyReqQueryVariables>(
    client,
    CheckUserSurveyReqDocument,
    variables,
    headers
  );

export const ProfileModalQueryDocument = `
    query ProfileModalQuery {
  user {
    __typename
    ... on UserResponse {
      _id
      email
      isEmailVerified
      signUpMethod
      marketingAgreement
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useProfileModalQueryQuery = <TData = ProfileModalQueryQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: ProfileModalQueryQueryVariables,
  options?: UseQueryOptions<ProfileModalQueryQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<ProfileModalQueryQuery, TError, TData>(
    variables === undefined ? ["ProfileModalQuery"] : ["ProfileModalQuery", variables],
    fetcher<ProfileModalQueryQuery, ProfileModalQueryQueryVariables>(
      client,
      ProfileModalQueryDocument,
      variables,
      headers
    ),
    options
  );
};

useProfileModalQueryQuery.getKey = (variables?: ProfileModalQueryQueryVariables) =>
  variables === undefined ? ["ProfileModalQuery"] : ["ProfileModalQuery", variables];

useProfileModalQueryQuery.fetcher = (
  client: GraphQLClient,
  variables?: ProfileModalQueryQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<ProfileModalQueryQuery, ProfileModalQueryQueryVariables>(
    client,
    ProfileModalQueryDocument,
    variables,
    headers
  );

export const UpdateAccountSettingsDocument = `
    mutation UpdateAccountSettings($input: UpdateAccountSettingsInput!) {
  updateAccountSettings(input: $input) {
    __typename
    ... on UserResponse {
      _id
      email
      isEmailVerified
      signUpMethod
      marketingAgreement
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useUpdateAccountSettingsMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<UpdateAccountSettingsMutation, TError, UpdateAccountSettingsMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<UpdateAccountSettingsMutation, TError, UpdateAccountSettingsMutationVariables, TContext>(
    ["UpdateAccountSettings"],
    (variables?: UpdateAccountSettingsMutationVariables) =>
      fetcher<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>(
        client,
        UpdateAccountSettingsDocument,
        variables,
        headers
      )(),
    options
  );
};

useUpdateAccountSettingsMutation.getKey = () => ["UpdateAccountSettings"];

useUpdateAccountSettingsMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateAccountSettingsMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>(
    client,
    UpdateAccountSettingsDocument,
    variables,
    headers
  );

export const EditorDocument = `
    query Editor($id: String!) {
  file(id: $id) {
    __typename
    ... on FileResponse {
      _id
      name
      content
    }
  }
}
    `;

export const useEditorQuery = <TData = EditorQuery, TError = unknown>(
  client: GraphQLClient,
  variables: EditorQueryVariables,
  options?: UseQueryOptions<EditorQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<EditorQuery, TError, TData>(
    ["Editor", variables],
    fetcher<EditorQuery, EditorQueryVariables>(client, EditorDocument, variables, headers),
    options
  );
};

useEditorQuery.getKey = (variables: EditorQueryVariables) => ["Editor", variables];

useEditorQuery.fetcher = (client: GraphQLClient, variables: EditorQueryVariables, headers?: RequestInit["headers"]) =>
  fetcher<EditorQuery, EditorQueryVariables>(client, EditorDocument, variables, headers);

export const HistoryListDocument = `
    query HistoryList($input: GetHistoriesInput!) {
  histories(input: $input) {
    __typename
    ... on HistoriesResponse {
      histories {
        __typename
        _id
        title
        createdAt
        wordCount
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useHistoryListQuery = <TData = HistoryListQuery, TError = unknown>(
  client: GraphQLClient,
  variables: HistoryListQueryVariables,
  options?: UseQueryOptions<HistoryListQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<HistoryListQuery, TError, TData>(
    ["HistoryList", variables],
    fetcher<HistoryListQuery, HistoryListQueryVariables>(client, HistoryListDocument, variables, headers),
    options
  );
};

useHistoryListQuery.getKey = (variables: HistoryListQueryVariables) => ["HistoryList", variables];

useHistoryListQuery.fetcher = (
  client: GraphQLClient,
  variables: HistoryListQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<HistoryListQuery, HistoryListQueryVariables>(client, HistoryListDocument, variables, headers);

export const HistoryPreviewDocument = `
    query HistoryPreview($id: String!) {
  history(historyId: $id) {
    __typename
    ... on HistoryResponse {
      _id
      content
      createdAt
      title
      wordCount
    }
  }
}
    `;

export const useHistoryPreviewQuery = <TData = HistoryPreviewQuery, TError = unknown>(
  client: GraphQLClient,
  variables: HistoryPreviewQueryVariables,
  options?: UseQueryOptions<HistoryPreviewQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<HistoryPreviewQuery, TError, TData>(
    ["HistoryPreview", variables],
    fetcher<HistoryPreviewQuery, HistoryPreviewQueryVariables>(client, HistoryPreviewDocument, variables, headers),
    options
  );
};

useHistoryPreviewQuery.getKey = (variables: HistoryPreviewQueryVariables) => ["HistoryPreview", variables];

useHistoryPreviewQuery.fetcher = (
  client: GraphQLClient,
  variables: HistoryPreviewQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<HistoryPreviewQuery, HistoryPreviewQueryVariables>(client, HistoryPreviewDocument, variables, headers);

export const EditorHeaderDocument = `
    query EditorHeader($id: String!) {
  file(id: $id) {
    __typename
    ... on FileResponse {
      _id
      name
      isPublic
      fileTreeNodeId
    }
  }
}
    `;

export const useEditorHeaderQuery = <TData = EditorHeaderQuery, TError = unknown>(
  client: GraphQLClient,
  variables: EditorHeaderQueryVariables,
  options?: UseQueryOptions<EditorHeaderQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<EditorHeaderQuery, TError, TData>(
    ["EditorHeader", variables],
    fetcher<EditorHeaderQuery, EditorHeaderQueryVariables>(client, EditorHeaderDocument, variables, headers),
    options
  );
};

useEditorHeaderQuery.getKey = (variables: EditorHeaderQueryVariables) => ["EditorHeader", variables];

useEditorHeaderQuery.fetcher = (
  client: GraphQLClient,
  variables: EditorHeaderQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<EditorHeaderQuery, EditorHeaderQueryVariables>(client, EditorHeaderDocument, variables, headers);

export const FileTreeWithWordCountOfStoryDocument = `
    query FileTreeWithWordCountOfStory($storyId: String!) {
  fileTreeWithWordCountOfStory(storyId: $storyId) {
    __typename
    ... on FileTreeWithWordCountResponse {
      fileTree {
        ...FileTreeNodeWithWordCount_Fragment
        children {
          ...FileTreeNodeWithWordCount_Fragment
        }
      }
    }
    ...BaseError_Fragment
  }
}
    ${FileTreeNodeWithWordCount_FragmentFragmentDoc}
${BaseError_FragmentFragmentDoc}`;

export const useFileTreeWithWordCountOfStoryQuery = <TData = FileTreeWithWordCountOfStoryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: FileTreeWithWordCountOfStoryQueryVariables,
  options?: UseQueryOptions<FileTreeWithWordCountOfStoryQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<FileTreeWithWordCountOfStoryQuery, TError, TData>(
    ["FileTreeWithWordCountOfStory", variables],
    fetcher<FileTreeWithWordCountOfStoryQuery, FileTreeWithWordCountOfStoryQueryVariables>(
      client,
      FileTreeWithWordCountOfStoryDocument,
      variables,
      headers
    ),
    options
  );
};

useFileTreeWithWordCountOfStoryQuery.getKey = (variables: FileTreeWithWordCountOfStoryQueryVariables) => [
  "FileTreeWithWordCountOfStory",
  variables,
];

useFileTreeWithWordCountOfStoryQuery.fetcher = (
  client: GraphQLClient,
  variables: FileTreeWithWordCountOfStoryQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<FileTreeWithWordCountOfStoryQuery, FileTreeWithWordCountOfStoryQueryVariables>(
    client,
    FileTreeWithWordCountOfStoryDocument,
    variables,
    headers
  );

export const CharacterDocument = `
    query Character($id: String!) {
  character(id: $id) {
    __typename
    ... on CharacterResponse {
      _id
      name
      profileImageUrl
      nodeId
      storyId
      descriptions {
        title
        content
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCharacterQuery = <TData = CharacterQuery, TError = unknown>(
  client: GraphQLClient,
  variables: CharacterQueryVariables,
  options?: UseQueryOptions<CharacterQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<CharacterQuery, TError, TData>(
    ["Character", variables],
    fetcher<CharacterQuery, CharacterQueryVariables>(client, CharacterDocument, variables, headers),
    options
  );
};

useCharacterQuery.getKey = (variables: CharacterQueryVariables) => ["Character", variables];

useCharacterQuery.fetcher = (
  client: GraphQLClient,
  variables: CharacterQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<CharacterQuery, CharacterQueryVariables>(client, CharacterDocument, variables, headers);

export const CharacterTreeOfStoryDocument = `
    query CharacterTreeOfStory($storyId: String!) {
  characterTreeOfStory(storyId: $storyId) {
    __typename
    ... on CharacterTreeResponse {
      characterTree {
        ...CharacterTreeNodeWithChildren_Fragment
        children {
          ...CharacterTreeNodeWithChildren_Fragment
        }
      }
    }
  }
}
    ${CharacterTreeNodeWithChildren_FragmentFragmentDoc}`;

export const useCharacterTreeOfStoryQuery = <TData = CharacterTreeOfStoryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: CharacterTreeOfStoryQueryVariables,
  options?: UseQueryOptions<CharacterTreeOfStoryQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<CharacterTreeOfStoryQuery, TError, TData>(
    ["CharacterTreeOfStory", variables],
    fetcher<CharacterTreeOfStoryQuery, CharacterTreeOfStoryQueryVariables>(
      client,
      CharacterTreeOfStoryDocument,
      variables,
      headers
    ),
    options
  );
};

useCharacterTreeOfStoryQuery.getKey = (variables: CharacterTreeOfStoryQueryVariables) => [
  "CharacterTreeOfStory",
  variables,
];

useCharacterTreeOfStoryQuery.fetcher = (
  client: GraphQLClient,
  variables: CharacterTreeOfStoryQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CharacterTreeOfStoryQuery, CharacterTreeOfStoryQueryVariables>(
    client,
    CharacterTreeOfStoryDocument,
    variables,
    headers
  );

export const GetMemoByStoryIdDocument = `
    query GetMemoByStoryId($storyId: String!) {
  getMemoByStoryId(storyId: $storyId) {
    __typename
    ... on MemoResponse {
      id
      storyId
      content
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useGetMemoByStoryIdQuery = <TData = GetMemoByStoryIdQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetMemoByStoryIdQueryVariables,
  options?: UseQueryOptions<GetMemoByStoryIdQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<GetMemoByStoryIdQuery, TError, TData>(
    ["GetMemoByStoryId", variables],
    fetcher<GetMemoByStoryIdQuery, GetMemoByStoryIdQueryVariables>(
      client,
      GetMemoByStoryIdDocument,
      variables,
      headers
    ),
    options
  );
};

useGetMemoByStoryIdQuery.getKey = (variables: GetMemoByStoryIdQueryVariables) => ["GetMemoByStoryId", variables];

useGetMemoByStoryIdQuery.fetcher = (
  client: GraphQLClient,
  variables: GetMemoByStoryIdQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<GetMemoByStoryIdQuery, GetMemoByStoryIdQueryVariables>(client, GetMemoByStoryIdDocument, variables, headers);

export const StoryHeaderDocument = `
    query StoryHeader($input: GetStoryInput!) {
  story(input: $input) {
    __typename
    ... on StoryResponse {
      _id
      title
      category
      coverImageUrl
      description
      tags
    }
  }
}
    `;

export const useStoryHeaderQuery = <TData = StoryHeaderQuery, TError = unknown>(
  client: GraphQLClient,
  variables: StoryHeaderQueryVariables,
  options?: UseQueryOptions<StoryHeaderQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<StoryHeaderQuery, TError, TData>(
    ["StoryHeader", variables],
    fetcher<StoryHeaderQuery, StoryHeaderQueryVariables>(client, StoryHeaderDocument, variables, headers),
    options
  );
};

useStoryHeaderQuery.getKey = (variables: StoryHeaderQueryVariables) => ["StoryHeader", variables];

useStoryHeaderQuery.fetcher = (
  client: GraphQLClient,
  variables: StoryHeaderQueryVariables,
  headers?: RequestInit["headers"]
) => fetcher<StoryHeaderQuery, StoryHeaderQueryVariables>(client, StoryHeaderDocument, variables, headers);

export const CheckUserOnboardingReqDocument = `
    query CheckUserOnboardingReq {
  __typename
  user {
    __typename
    ... on UserResponse {
      onboarding {
        isOnboardingCompleted
      }
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useCheckUserOnboardingReqQuery = <TData = CheckUserOnboardingReqQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: CheckUserOnboardingReqQueryVariables,
  options?: UseQueryOptions<CheckUserOnboardingReqQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<CheckUserOnboardingReqQuery, TError, TData>(
    variables === undefined ? ["CheckUserOnboardingReq"] : ["CheckUserOnboardingReq", variables],
    fetcher<CheckUserOnboardingReqQuery, CheckUserOnboardingReqQueryVariables>(
      client,
      CheckUserOnboardingReqDocument,
      variables,
      headers
    ),
    options
  );
};

useCheckUserOnboardingReqQuery.getKey = (variables?: CheckUserOnboardingReqQueryVariables) =>
  variables === undefined ? ["CheckUserOnboardingReq"] : ["CheckUserOnboardingReq", variables];

useCheckUserOnboardingReqQuery.fetcher = (
  client: GraphQLClient,
  variables?: CheckUserOnboardingReqQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<CheckUserOnboardingReqQuery, CheckUserOnboardingReqQueryVariables>(
    client,
    CheckUserOnboardingReqDocument,
    variables,
    headers
  );

export const TrashCanFilePreviewDocument = `
    query TrashCanFilePreview($fileId: String!) {
  file(id: $fileId) {
    __typename
    ... on FileResponse {
      _id
      name
      content
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useTrashCanFilePreviewQuery = <TData = TrashCanFilePreviewQuery, TError = unknown>(
  client: GraphQLClient,
  variables: TrashCanFilePreviewQueryVariables,
  options?: UseQueryOptions<TrashCanFilePreviewQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<TrashCanFilePreviewQuery, TError, TData>(
    ["TrashCanFilePreview", variables],
    fetcher<TrashCanFilePreviewQuery, TrashCanFilePreviewQueryVariables>(
      client,
      TrashCanFilePreviewDocument,
      variables,
      headers
    ),
    options
  );
};

useTrashCanFilePreviewQuery.getKey = (variables: TrashCanFilePreviewQueryVariables) => [
  "TrashCanFilePreview",
  variables,
];

useTrashCanFilePreviewQuery.fetcher = (
  client: GraphQLClient,
  variables: TrashCanFilePreviewQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<TrashCanFilePreviewQuery, TrashCanFilePreviewQueryVariables>(
    client,
    TrashCanFilePreviewDocument,
    variables,
    headers
  );

export const TrashCanFileTreeOfStoryDocument = `
    query TrashCanFileTreeOfStory($storyId: String!) {
  trashCanFileTreeOfStory(storyId: $storyId) {
    __typename
    ... on TrashCanFileTreeResponse {
      trashCanFileTree {
        ...TrashCanFileTreeNode_Fragment
        children {
          ...TrashCanFileTreeNode_Fragment
        }
      }
    }
    ...BaseError_Fragment
  }
}
    ${TrashCanFileTreeNode_FragmentFragmentDoc}
${BaseError_FragmentFragmentDoc}`;

export const useTrashCanFileTreeOfStoryQuery = <TData = TrashCanFileTreeOfStoryQuery, TError = unknown>(
  client: GraphQLClient,
  variables: TrashCanFileTreeOfStoryQueryVariables,
  options?: UseQueryOptions<TrashCanFileTreeOfStoryQuery, TError, TData>,
  headers?: RequestInit["headers"]
) => {
  return useQuery<TrashCanFileTreeOfStoryQuery, TError, TData>(
    ["TrashCanFileTreeOfStory", variables],
    fetcher<TrashCanFileTreeOfStoryQuery, TrashCanFileTreeOfStoryQueryVariables>(
      client,
      TrashCanFileTreeOfStoryDocument,
      variables,
      headers
    ),
    options
  );
};

useTrashCanFileTreeOfStoryQuery.getKey = (variables: TrashCanFileTreeOfStoryQueryVariables) => [
  "TrashCanFileTreeOfStory",
  variables,
];

useTrashCanFileTreeOfStoryQuery.fetcher = (
  client: GraphQLClient,
  variables: TrashCanFileTreeOfStoryQueryVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<TrashCanFileTreeOfStoryQuery, TrashCanFileTreeOfStoryQueryVariables>(
    client,
    TrashCanFileTreeOfStoryDocument,
    variables,
    headers
  );

export const DeleteFileTreeNodePermanentlyDocument = `
    mutation DeleteFileTreeNodePermanently($id: String!) {
  deleteFileTreeNodePermanently(id: $id) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useDeleteFileTreeNodePermanentlyMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteFileTreeNodePermanentlyMutation,
    TError,
    DeleteFileTreeNodePermanentlyMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"]
) => {
  return useMutation<
    DeleteFileTreeNodePermanentlyMutation,
    TError,
    DeleteFileTreeNodePermanentlyMutationVariables,
    TContext
  >(
    ["DeleteFileTreeNodePermanently"],
    (variables?: DeleteFileTreeNodePermanentlyMutationVariables) =>
      fetcher<DeleteFileTreeNodePermanentlyMutation, DeleteFileTreeNodePermanentlyMutationVariables>(
        client,
        DeleteFileTreeNodePermanentlyDocument,
        variables,
        headers
      )(),
    options
  );
};

useDeleteFileTreeNodePermanentlyMutation.getKey = () => ["DeleteFileTreeNodePermanently"];

useDeleteFileTreeNodePermanentlyMutation.fetcher = (
  client: GraphQLClient,
  variables: DeleteFileTreeNodePermanentlyMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<DeleteFileTreeNodePermanentlyMutation, DeleteFileTreeNodePermanentlyMutationVariables>(
    client,
    DeleteFileTreeNodePermanentlyDocument,
    variables,
    headers
  );

export const RestoreFileTreeNodeDocument = `
    mutation RestoreFileTreeNode($id: String!) {
  restoreFileTreeNode(id: $id) {
    __typename
    ... on SuccessFailureResponse {
      success
    }
    ...BaseError_Fragment
  }
}
    ${BaseError_FragmentFragmentDoc}`;

export const useRestoreFileTreeNodeMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<RestoreFileTreeNodeMutation, TError, RestoreFileTreeNodeMutationVariables, TContext>,
  headers?: RequestInit["headers"]
) => {
  return useMutation<RestoreFileTreeNodeMutation, TError, RestoreFileTreeNodeMutationVariables, TContext>(
    ["RestoreFileTreeNode"],
    (variables?: RestoreFileTreeNodeMutationVariables) =>
      fetcher<RestoreFileTreeNodeMutation, RestoreFileTreeNodeMutationVariables>(
        client,
        RestoreFileTreeNodeDocument,
        variables,
        headers
      )(),
    options
  );
};

useRestoreFileTreeNodeMutation.getKey = () => ["RestoreFileTreeNode"];

useRestoreFileTreeNodeMutation.fetcher = (
  client: GraphQLClient,
  variables: RestoreFileTreeNodeMutationVariables,
  headers?: RequestInit["headers"]
) =>
  fetcher<RestoreFileTreeNodeMutation, RestoreFileTreeNodeMutationVariables>(
    client,
    RestoreFileTreeNodeDocument,
    variables,
    headers
  );
