import { OAuthConfig } from "./OAuthConfig";

export type OAuthProvider = "google";

export type AuthenticateParamsState = {
  botId?: string;
};

export type AuthenticateParams = {
  state?: AuthenticateParamsState;
};

export default class OAuthClient {
  config: (typeof OAuthConfig)[keyof typeof OAuthConfig];

  provider: OAuthProvider;

  constructor(provider: OAuthProvider) {
    this.provider = provider;
    this.config = OAuthConfig[provider];
  }

  public authenticate({ state = {} }: AuthenticateParams = {}): void {
    const { authorizationUrl, clientId, callbackUrl, scope } = this.config;
    const params = new URLSearchParams({
      client_id: clientId,
      response_type: "code",
      redirect_uri: callbackUrl,
      state: JSON.stringify(state),
    });

    if (scope) {
      params.set("scope", scope);
    }
    if (this.provider === "google") {
      params.set("access_type", "offline");
      params.set("prompt", "consent");
    }

    const authUrl = `${authorizationUrl}?${params.toString()}`;
    window.location.href = authUrl;
  }
}
